import React, { Fragment } from "react";

const Clock = ({ timerDays, timerHours, timerMinutes, timerSeconds }) => {
  return (
    <Fragment>
      <section className="timer-container mt-2">
        <section className="timer">
          <div className="clocks">
            <section>
              <p className="large">{timerDays}</p>
              <p className="small">Days</p>
            </section>
            <section>
              <p className="large">{timerHours}</p>
              <p className="small">Hours</p>
            </section>{" "}
            <section>
              <p className="large">{timerMinutes}</p>
              <p className="small">Minutes</p>
            </section>{" "}
            <section>
              <p className="large">{timerSeconds}</p>
              <p className="small">Seconds</p>
            </section>
          </div>
        </section>
      </section>
    </Fragment>
  );
};

export default Clock;
