import React, { useState, useEffect } from "react";
import Header from "../components/header/Header";
import Hero from "../components/header/Hero";
import { FaUserFriends, FaAward, FaSeedling } from "react-icons/fa";
import { GiPublicSpeaker, GiDeliveryDrone } from "react-icons/gi";
import { SiKnowledgebase } from "react-icons/si";
import { MdOutlineInvertColors } from "react-icons/md";
import { HiNewspaper } from "react-icons/hi";
import Carousel from "react-bootstrap/Carousel";
import "./style.css";
import Footer from "../components/footer/Footer";
import Partners from "./Partners";
import Testimonials from "./Testimonials";
import { Link } from "react-router-dom";
import Welcome from "./Welcome";

const Home = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);
  return (
    <>
      {loading === false ? (
        <>
          <Header />
          <Hero />
          <div className="home">
            <div className="theme-section">
              <div className="theme">
                <div className="col-lg-10 offset-md-1 parallal-content">
                  <h2>About NYE Summit</h2>
                  <p>
                    Nepal Youth Entrepreneurship Summit (NYES) 2023 marks an
                    important milestone in the roadmap towards boosting an
                    ecosystem for entrepreneurs in Nepal. This highly
                    interactive event will provide information to the audience
                    on how to transform ideas to successful business, challenges
                    and opportunities for entrepreneurs in Nepal, insights from
                    industry veterans, governments’ take on innovative
                    businesses and various such topics pertaining to the
                    business environment in Nepal. This is a follow-up summit to
                    the first Nepal Youth Entrepreneurship Summit held on
                    February 14, 2019, and second Nepal Youth Entrepreneurship
                    Summit held on September 11, 2022 inaugurated by current
                    Prime Minister of Nepal, The Right Honorable Pushpa Kamal
                    Dahal.
                    <br />
                    <br />
                    Nepal Youth Entrepreneurship Summit (NYES) 2023 with the
                    theme of <b>
                      "Youth Innovation, Investment and Growth"
                    </b>{" "}
                    will be jointly organized by Nepal Government Youth and
                    Sports Ministry, National Youth Council, along with the
                    Bagmati Province Government and Nepal Business Institute
                    (NBI). Third edition of this summit is being held on
                    September 16 th , 2023 in Kathmandu. The summit includes
                    high-level dialogue on the development, promotion and
                    expansion of youth entrepreneurship, experience sharing,
                    story-sharing, group discussions, networking, award
                    distribution with presentations of successful young
                    entrepreneurs, entrepreneurs&#39; walk, exhibition of
                    innovative work being done in the world market, investment
                    agreements and other programs. Summit has been designed as a
                    unique platform to create youth success, potential and
                    opportunities. Third NYE summit helps to make entrepreneur
                    friendly environment, give confidence, courage and sense of
                    responsibility to the youth, to scale up innovation and
                    entrepreneurship, to enhance agenda of economic prosperity,
                    to attract youth on subsidy and investment model of the
                    government.
                  </p>
                </div>
              </div>
            </div>
            {/* start about */}
            <div className="please-join">
              <div className="">
                <h3 className="pb-2 pt-3">Previous Event's Highlights</h3>
                <div className="videos pt-4">
                  <iframe
                    src="https://www.youtube.com/embed/ZGHXCBahVLk"
                    className="w-100"
                    frameborder="0"
                  ></iframe>{" "}
                </div>
              </div>
            </div>
            {/* end about */}
            {/* start highlight */}
            <div className="highlight_nye p-5" id="highlight">
              <div className="containers">
                <h3 className="pb-5 pt-3">KEY REASONS TO ATTEND THIS SUMMIT</h3>
                <div className="">
                  <div className="rows">
                    <div className="highlight-card">
                      <div className="icon">
                        <img src="./images/" alt="" />
                        <img src="./images/icon/learning.png" alt="" />
                      </div>
                      <div className="text mt-3">
                        <h5>Learning and Mentoring</h5>
                      </div>
                    </div>
                    <div className="highlight-card">
                      <div className="icon">
                        <img src="./images/icon/startup.png" alt="" />
                      </div>
                      <div className="text mt-3">
                        <h5>Startup Knowledge Exchange</h5>
                      </div>
                    </div>
                    <div className="highlight-card">
                      <div className="icon">
                        <img src="./images/icon/networking.png" alt="" />
                      </div>
                      <div className="text mt-3">
                        <h5>Networking Opportunities</h5>
                      </div>
                    </div>
                    <div className="highlight-card">
                      <div className="icon">
                        <img src="./images/icon/innovative.png" alt="" />
                      </div>
                      <div className="text mt-3">
                        <h5>Innovative Exhibition</h5>
                      </div>
                    </div>
                    <div className="highlight-card">
                      <div className="icon">
                        <img src="./images/icon/fostering.png" alt="" />
                      </div>
                      <div className="text mt-3">
                        <h5>Fostering Youth Entrepreneurship</h5>
                      </div>
                    </div>
                    <div className="highlight-card">
                      <div className="icon">
                        <img src="./images/icon/seed.png" alt="" />
                      </div>
                      <div className="text mt-3">
                        <h5>Seed Funding opportunities</h5>
                      </div>
                    </div>
                    <div className="highlight-card">
                      <div className="icon">
                        <img src="./images/icon/financial.png" alt="" />
                      </div>
                      <div className="text">
                        <h5>Financial Literacy and Access to Finance</h5>
                      </div>
                    </div>
                    <div className="highlight-card">
                      <div className="icon">
                        <img src="./images/icon/sdgs.png" alt="" />
                      </div>
                      <div className="text">
                        <h5>Effective Youth Engagement in SDGs</h5>
                      </div>
                    </div>

                    <div className="highlight-card p-3 pb-4">
                      <div className="iconn">
                        <FaUserFriends className="icon" />
                      </div>
                      <div className="text">
                        <h5 className="ps-5 mts text-center ">
                          400 + <br /> Youth Delegates
                        </h5>
                      </div>
                    </div>
                    <div className="highlight-card p-3 pb-4">
                      <div className="iconn">
                        <GiPublicSpeaker className="icon" />
                      </div>
                      <div className="text">
                        <h5 className="ps-5 mts text-center  ">
                          30+ <br /> Speakers
                        </h5>
                      </div>
                    </div>
                    <div className="highlight-card p-3 pb-4">
                      <div className="iconn">
                        <FaSeedling className="icon" />
                      </div>
                      <div className="text">
                        <h5 className="ps-5 mts text-center  ">
                          30+ <br />
                          Mentor
                        </h5>
                      </div>
                    </div>
                    <div className="highlight-card p-3 pb-4">
                      <div className="iconn">
                        <MdOutlineInvertColors className="icon" />
                      </div>
                      <div className="text">
                        <h5 className="ps-5 mts text-center  ">
                          100+ <br />
                          Startup Investor
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end highlight */}
            {/* Distinguished Guest */}
            <div className="distinguished-guest pb-5 pt-md-3 ">
              <div className="guest-header p-4 pt-md-5">
                <h3 className="pb-md-4">Distinguished Guests</h3>
              </div>
              <div className="guest pb-4">
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/distinguished/Prachanda.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title pb-4">
                      <img
                        src="./images/sarkar.jpg"
                        height={50}
                        alt="...."
                        className="pt-2"
                      />
                      <div className="card-name mt-2 pt-2 ">
                        <h4>Rt. Hon. Pushpa Kamal Dahal</h4>
                      </div>
                      <div className="card-position">
                        <p>Prime Minister of Nepal</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/distinguished/finance.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title pb-4">
                      <img
                        src="./images/sarkar.jpg"
                        height={50}
                        alt="...."
                        className="pt-2"
                      />
                      <div className="card-name mt-2 pt-2 ">
                        <h4>Hon. Prakash Sharan Mahat</h4>
                      </div>
                      <div className="card-position">
                        <p>Minister For Finance (GoN)</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/distinguished/rekha.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title pb-4">
                      <img
                        src="./images/sarkar.jpg"
                        height={50}
                        alt="...."
                        className="pt-2"
                      />
                      <div className="card-name mt-2 pt-2 ">
                        <h4>Hon. Rekha Sharma</h4>
                      </div>
                      <div className="card-position">
                        <p>
                          Minister For Communications and Information Technology
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/distinguished/rameshrijal.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title pb-4">
                      <img
                        src="./images/sarkar.jpg"
                        height={50}
                        alt="...."
                        className="pt-2"
                      />
                      <div className="card-name mt-2 pt-2 ">
                        <h4>Hon. Ramesh Rijal</h4>
                      </div>
                      <div className="card-position">
                        <p>Minister for Industry, Commerce and Supplies</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/guests/dil.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title pb-4">
                      <img
                        src="./images/sarkar.jpg"
                        height={50}
                        alt="...."
                        className="pt-2"
                      />
                      <div className="card-name mt-2 pt-2">
                        <h4>Hon. Dig Bahadur Limbu </h4>
                      </div>
                      <div className="card-position">
                        <p>Minister for Youth and Sports Ministry, GoN</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Intenational Speaker */}
            {/* <div
              className="distinguished-guest pb-4 pt-md-4 pb-md-5"
              style={{ backgroundColor: "#f2f2f2" }}
            >
              <div className="keynote p-4 pb-md-5 pt-md-5">
                <h3>International Speakers</h3>
              </div>
              <div className="guest pb-md-3">
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/international/qamar.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/international/strategic.png"
                        height={50}
                        alt="...."
                        className="pt-1"
                      />
                      <div className="card-name mt-2">
                        <h4>Mr. Qamar Saleem</h4>
                      </div>
                      <div className="card-position">
                        <p>
                          Advisor to Asia Pacific Vice President, IFC, The World
                          Bank Group
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/international/nona.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/international/eu.png"
                        height={50}
                        alt="...."
                        className="pt-1"
                      />
                      <div className="card-name mt-2">
                        <h4>H.E Nona Deprez</h4>
                      </div>
                      <div className="card-position">
                        <p>Ambassador, European Union Delegation to Nepal</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/international/faris.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/international/world.png"
                        height={70}
                        alt="...."
                        className=""
                      />
                      <div className="card-name mt-2">
                        <h4>Mr. Faris Hadad-Zervos</h4>
                      </div>
                      <div className="card-position">
                        <p>
                          Country Director, Maldives, Nepal and Sri Lanka, South
                          Asia, The World Bank Group
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/international/imf.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/international/imf.png"
                        height={70}
                        alt="...."
                        className="pt-1"
                      />
                      <div className="card-name mt-2">
                        <h4>Ms. Teresa Daban Sanchez</h4>
                      </div>
                      <div className="card-position">
                        <p>IMF Country Representative</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/international/silvanus.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/international/diploma.png"
                        height={50}
                        alt="...."
                        className="pt-1"
                      />
                      <div className="card-name mt-2">
                        <h4>Joseph Silvanus</h4>
                      </div>
                      <div className="card-position">
                        <p>
                          Senior Financial Consultant/ Board Chair Trustee,
                          Dolma Consulting
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Speakers */}

            <div
              className=" speakers distinguished-guest pb-4 pb-md-5  pt-md-4"
              id="speakers"
            >
              <div className="speaker p-4 pb-md-4 pt-md-5">
                <h3 className="pb-md-3">Speakers</h3>
              </div>
              <div className="guest">
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/neelam.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/nrb.jpg"
                        height={50}
                        alt="...."
                        className="pt-1"
                      />
                      <div className="card-name mt-2">
                        <h4>Dr. Neelam Dhungana Timsina</h4>
                      </div>
                      <div className="card-position">
                        <p>Acting Governor</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/sushil-bhatta.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/ibn.png"
                        height={50}
                        alt="...."
                        className="pt-1"
                      />
                      <div className="card-name mt-2">
                        <h4>Mr. Sushil Bhatta</h4>
                      </div>
                      <div className="card-position">
                        <p>CEO, Investment Board Nepal</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/roman.png"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/indrive.png"
                        height={50}
                        alt="...."
                        className="pt-3"
                      />
                      <div className="card-name mt-2">
                        <h4>Mr. Roman N. Ermoshin</h4>
                      </div>
                      <div className="card-position">
                        <p>
                          Director, Asia Pacific Region,{" "}
                          <span style={{ textTransform: "lowercase" }}>in</span>
                          Drive
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/ashish.png"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/alogo.png"
                        height={50}
                        alt="...."
                        className="pt-1"
                      />
                      <div className="card-name mt-2">
                        <h4>Mr. Ashish Sharma</h4>
                      </div>
                      <div className="card-position">
                        <p>Managing Director, Ashviana Corporation</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/dilip.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/worldlink.png"
                        height={20}
                        alt="...."
                        className="mt-3 mb-2"
                      />
                      <div className="card-name mt-2">
                        <h4>Mr. Dileep Agrawal</h4>
                      </div>
                      <div className="card-position">
                        <p>Chairman, Worldlink Communications</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="guest">
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/bhawani.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/fncci.jpg"
                        height={50}
                        alt="...."
                        className="pt-1"
                      />
                      <div className="card-name mt-2">
                        <h4>Mrs. Bhawani Rana</h4>
                      </div>
                      <div className="card-position">
                        <p>Former President, FNCCI</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/sunil.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/nba.jpg"
                        height={50}
                        alt="...."
                        className="pt-1"
                      />
                      <div className="card-name mt-2">
                        <h4>Mr. Sunil KC</h4>
                      </div>
                      <div className="card-position">
                        <p>President, NBA</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/mahesh.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/nrna.png"
                        height={60}
                        alt="...."
                        className="pt-3"
                      />
                      <div className="card-name mt-2">
                        <h4>Mr. Mahesh Shrestha</h4>
                      </div>
                      <div className="card-position">
                        <p>Vice President IES Japan</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/suyog.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/sangrila.jpg"
                        height={50}
                        alt="...."
                        className="pt-1"
                      />
                      <div className="card-name mt-2">
                        <h4>Mr. Suyog Shrestha</h4>
                      </div>
                      <div className="card-position">
                        <p>CEO, Shangri-la Development Bank Ltd.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/ghanashyam.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/wwf.png"
                        height={40}
                        alt="...."
                        className="mt-3 mb-2"
                      />
                      <div className="card-name mt-2">
                        <h4>Dr. Ghana S. Gurung</h4>
                      </div>
                      <div className="card-position">
                        <p>Country Representative, WWF Nepal</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="guest">
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/vidhusi.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/gold.png"
                        height={40}
                        alt="...."
                        className="pt-2 ps-3 pe-3"
                      />
                      <div className="card-name mt-2">
                        <h4>Mrs. Vidusi Rana</h4>
                      </div>
                      <div className="card-position">
                        <p>Executve Director GoldStar</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/sunita.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/bc.png"
                        height={50}
                        alt="...."
                        className="pt-1"
                      />
                      <div className="card-name mt-2">
                        <h4>Ms. Sunita Basnet</h4>
                      </div>
                      <div className="card-position">
                        <p>Head of the School of Business</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/diwas.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/phonepay.png"
                        height={50}
                        alt="...."
                        className="pt-3"
                      />
                      <div className="card-name mt-2">
                        <h4>Mr. Diwas Sapkota</h4>
                      </div>
                      <div className="card-position">
                        <p>Chief Executive Officer at Fonepay</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/suyog.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/sangrila.jpg"
                        height={50}
                        alt="...."
                        className="pt-1"
                      />
                      <div className="card-name mt-2">
                        <h4>Mr. Suyog Shrestha</h4>
                      </div>
                      <div className="card-position">
                        <p>CEO, Shangri-la Development Bank Ltd.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="guest-card">
                    <div className="card-img">
                      <img
                        src="./images/speakers/ghanashyam.jpg"
                        alt="...."
                        className="w-100"
                      />
                    </div>
                    <div className="card-title">
                      <img
                        src="./images/speakers/wwf.png"
                        height={20}
                        alt="...."
                        className="mt-3 mb-2"
                      />
                      <div className="card-name mt-2">
                        <h4>Dr. Ghana S. Gurung</h4>
                      </div>
                      <div className="card-position">
                        <p>Country Representative, WWF Nepal</p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="view-all">
                <Link to="/all-speakers">
                  <button>View All</button>
                </Link>
              </div>
            </div>

            {/* news section */}
            <div className="news-video pt-5 mt-lg-4 mt-0 pb-5">
              <div className="news container">
                <div className="row ">
                  <div className="col-lg-7">
                    <div className="video-section">
                      <div className="video-head pb-3">
                        <h3>Video Gallery</h3>
                      </div>
                      <div className="videos p-2">
                        <Carousel fade>
                          <Carousel.Item>
                            <iframe
                              className="w-100"
                              src="https://www.youtube.com/embed/iOrsEyJ3n-A"
                              title="YouTube video player"
                              frameBorder={0}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen=""
                            />
                          </Carousel.Item>
                          <Carousel.Item>
                            <iframe
                              className="w-100"
                              src="https://www.youtube.com/embed/F1fmKo5AQoI"
                              title="YouTube video player"
                              frameBorder={0}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen=""
                            />
                          </Carousel.Item>
                          <Carousel.Item>
                            <iframe
                              className="w-100"
                              src="https://www.youtube.com/embed/m6sVT4nAP5g"
                              title="YouTube video player"
                              frameBorder={0}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen=""
                            />
                          </Carousel.Item>
                          <Carousel.Item>
                            <iframe
                              className="w-100"
                              src="https://www.youtube.com/embed/x5beW-QteIo"
                              title="YouTube video player"
                              frameBorder={0}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen=""
                            />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="news-section">
                      <div className="news-head pb-3">
                        <h3>News Section</h3>
                      </div>
                      <div className="news-section pt-1 ">
                        <div className="news-card">
                          <div className="d-flex">
                            <div className="col-md-4">
                              <img
                                src="./images/worldlinknews.jpg"
                                className="w-100 p-1"
                                alt="news__image"
                              />
                            </div>
                            <div className="col-md-8">
                              <a
                                href="https://www.ratopati.com/story/379613?fbclid=IwAR3m-BnwAstN_FO2Igrw1F1OVYz38efMlc1C8KlKPX5ep4dfMevu384eraY"
                                target="__blank"
                                className="text-decoration-none text-dark"
                              >
                                <h5 className="pt-3 pt-md-2 p-2">
                                  तेस्रो युवा उद्यमशीलता शिखर सम्मेलनको इन्टरनेट
                                  पार्टनरमा वर्ल्डलिंक
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="news-card">
                          <div className="d-flex">
                            <div className="col-md-4">
                              <img
                                src="./images/newnew.jpg"
                                className="w-100 p-1"
                                alt="news__image"
                              />
                            </div>
                            <div className="col-md-8">
                              <a
                                href="https://www.merolagani.com/NewsDetail.aspx?newsID=93594&fbclid=IwAR0pbTwpWaVhGiG9MW_JUrIoJNP4pj2HIv5r3Cy2GJLD5pKX1RdOGhMMiPk"
                                target="__blank"
                                className="text-decoration-none text-dark"
                              >
                                <h5 className="pt-3 pt-md-2 p-2">
                                  युवा परिषद् र एनबीआईको आयोजनामा तेस्रो युवा
                                  उद्यमशीलता शिखर सम्मेलन हुँदै
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="news-card">
                          <div className="d-flex">
                            <div className="col-md-4">
                              <img
                                src="./images/newnew.jpg"
                                className="w-100 p-1"
                                alt="news__image"
                              />
                            </div>
                            <div className="col-md-8">
                              <a
                                href="https://bizpati.com/update/71746?fbclid=IwAR1SOFEreo29ACWvmVzQY-QmQFo8hPdSaWjW4WF2pR0--PBtBITRa7mq8TM"
                                target="__blank"
                                className="text-decoration-none text-dark"
                              >
                                <h5 className="pt-3 pt-md-2 p-2">
                                  तेस्रो युवा उद्यमशीलता शिखर सम्मेलन हुँदै
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                        <hr />

                        <div className="news-card">
                          <div className="d-flex">
                            <div className="col-md-4">
                              <img
                                src="./images/snews2.jpg"
                                className="w-100 p-1"
                                alt="news__image"
                              />
                            </div>
                            <div className="col-md-8">
                              <a
                                href="https://www.nayapatrikadaily.com/news-details/93809/2022-08-19"
                                target="__blank"
                                className="text-decoration-none text-dark"
                              >
                                <h5 className="pt-3 pt-md-2 p-2">
                                  युवा उद्यमशीलता सम्मेलनको टाइटल स्पोन्सरमा
                                  राष्ट्रिय वाणिज्य बैंक
                                </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="more-button">
                  <Link to="/news-activities">
                    <button>Read More</button>
                  </Link>
                </div>
              </div>
            </div>
            {/* news-section end */}
            <div className="gallery">
              <div className=" container">
                <div className="gallery-head pb-3">
                  <h3>
                    <span>glimpse of</span> <br /> previous summit
                  </h3>
                </div>
                <div className="gallery-section">
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-image">
                      <img
                        src="./images/i1.JPG"
                        className="w-100"
                        alt="gallery_image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-image">
                      <img
                        src="./images/2.jpg"
                        className="w-100"
                        alt="gallery_image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-image">
                      <img
                        src="./images/3.jpg"
                        className="w-100"
                        alt="gallery_image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-image">
                      <img
                        src="./images/4.jpg"
                        className="w-100"
                        alt="gallery_image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-image">
                      <img
                        src="./images/5.jpg"
                        className="w-100"
                        alt="gallery_image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-image">
                      <img
                        src="./images/6.jpg"
                        className="w-100"
                        alt="gallery_image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-image">
                      <img
                        src="./images/7.jpg"
                        className="w-100"
                        alt="gallery_image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-image">
                      <img
                        src="./images/8.jpg"
                        className="w-100"
                        alt="gallery_image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="view-all pt-5">
                <center>
                  <Link to="/gallery">
                    <button>View All</button>
                  </Link>
                </center>
              </div>
            </div>
            <Testimonials />
            {/* about logo */}
            <div className="about-logo mt-md-5 pt-md-4">
              <div className="welcome pt-md-5 pb-md-4">
                <div className="container">
                  {" "}
                  <h2 className="text-white text-center pt-3 pt-md-5 pb-3">
                    About The Logo
                  </h2>
                  <div className="row w-100 pt-md-2">
                    <div className="col-md-5 p-4">
                      <div className="video pt-md-3">
                        <video controls autoPlay={false} className="w-100">
                          <source
                            src="./images/aboutlogo.mp4"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div>
                    <div className="col-md-7 p-4 pt-0 pt-md-4">
                      <div className="text ps-3 ps-md-0">
                        <p className="pt-lg-5">
                          The icon represents top of summit and nationality. The
                          two icons one is Moon represent the bright light,
                          calmness and purity of spirit and sun represents
                          resolve and endurance. the icon represents symbol of
                          youth holding individual aims and being independent
                          entrepreneurs. The icon represents the industrial
                          symbol as a chain connecting and unifying development
                          of whole youth community.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Partners />
            {/* acknowledge by */}
            <div
              className="partners container pt-5  pb-4"
              style={{ borderBottom: "3px solid #f54b02" }}
            >
              <div className="title-sponser">
                <div className="partners-head pb-3">
                  <h3>Acknowledged By</h3>
                </div>
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <div className="mt-md-1">
                      <center>
                        <img
                          src="./images/partners/nrb.jpg"
                          className=""
                          alt=""
                        />
                        <br />
                        <span
                          style={{
                            color: "#0b76bc",
                          }}
                        >
                          Nepal Rastra Bank (NRB)
                        </span>
                      </center>
                    </div>
                  </div>
                  <div className="col-md-3 mb-2">
                    <div className="mt-md-1">
                      <center>
                        <img
                          src="./images/partners/nba.jpg"
                          className=""
                          alt=""
                        />
                        <br />
                        <span
                          style={{
                            color: "#0b76bc",
                          }}
                        >
                          Nepal Bankers' Association (NBA)
                        </span>
                      </center>
                    </div>
                  </div>
                  <div className="col-md-3 mb-2">
                    <div className="mt-md-1">
                      <center>
                        <img
                          src="./images/partners/dba.png"
                          className="w-100 mt-lg-4 ps-3 pe-3 p-md-0"
                          alt=""
                        />
                        <br />
                        <span
                          style={{
                            color: "#0b76bc",
                          }}
                        >
                          Development Bankers Association Nepal (DBAN)
                        </span>
                      </center>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mt-md-1">
                      <center>
                        <img
                          src="./images/partners/mba.png"
                          className=""
                          height={100}
                          alt=""
                        />
                        <br />
                        <span
                          style={{
                            color: "#0b76bc",
                          }}
                        >
                          Nepal Microfinance Bankers` Association (NMBA)
                        </span>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* dks */}
            {/* connected by */}
            <div
              className="powered container pt-0 pt-lg-5 pb-5 "
              style={{
                borderBottom: "3px solid #154a9c",
              }}
            >
              <div className="row w-100 ">
                <div className="col-md-4 col-md-4 pt-5 pt-md-0">
                  <div className="partners-head pb-2">
                    <h3>Connected By</h3>
                  </div>
                  <div className="">
                    <center>
                      <img
                        src="./images/nta.png"
                        height={130}
                        className=""
                        alt=""
                      />
                      <br />
                      <span
                        style={{
                          color: "#0b76bc",
                        }}
                      >
                        Nepal Telecommunications Authority (NTA)
                      </span>
                    </center>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="partners-head pb-3 pt-5 pt-md-0">
                    <h3>Endorsed By</h3>
                  </div>
                  <div>
                    <center>
                      <img
                        src="./images/partners/glogo.png"
                        height={120}
                        alt="..."
                      />
                      <br />
                      <span
                        style={{
                          color: "#0b76bc",
                        }}
                      >
                        Prime Minister Agricultural Modernisation Project
                        (PMAMP)
                      </span>
                    </center>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="partners-head pt-lg-0 pt-4 pb-1">
                    <h3>Telecommunication Partner</h3>
                  </div>
                  <div>
                    <div className="pt-lg-2">
                      <center>
                        <img
                          src="./images/partners/ntcs.jpg"
                          alt=""
                          className=" "
                          height={120}
                        />
                        <br />
                        <span
                          style={{
                            color: "#0b76bc",
                          }}
                        >
                          Nepal Telecom (NTC)
                        </span>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* check */}
            <div
              className="partners container pt-5  pb-5"
              style={{ borderBottom: "3px solid #f54b02" }}
            >
              <div className="title-sponser">
                <div className="row">
                  <div className="col-md-6 col-md-4 pt-5 pt-md-0">
                    <div className="partners-head pb-3">
                      <h3>Development Partner</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <center>
                          <img
                            src="./images/partners/wv.jpg"
                            className=" mt-4 pt-lg-2 wv w-100 "
                            alt=""
                          />
                          <br />
                          <span
                            style={{
                              color: "#0b76bc",
                            }}
                          >
                            World Vision International
                          </span>
                        </center>
                      </div>
                      <div className="col-md-6">
                        <center>
                          <img
                            src="./images/partners/wwf.png"
                            className="wwf"
                            alt=""
                          />
                          <br />
                          <span
                            style={{
                              color: "#0b76bc",
                            }}
                          >
                            World Wide Fund
                          </span>
                        </center>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6"></div> */}
                  <div className="col-md-3 pt-5  pt-md-0">
                    <div className="partners-head pb-3">
                      <h3>Internet Partner</h3>
                    </div>
                    <div className="title-sponser">
                      <center>
                        <img
                          src="./images/partners/worldlink.png"
                          className="w-100 worldlink mt-4 pt-lg-3"
                          alt=""
                        />
                        <br />
                        <span
                          style={{
                            color: "#0b76bc",
                          }}
                        >
                          WorldLink Communications Ltd.
                        </span>
                      </center>
                    </div>
                  </div>
                  <div className="col-md-3 partner pt-4 pt-lg-0">
                    <div className="partners-head pb-0 pb-lg-3">
                      <h3>Digital Payment Partner</h3>
                    </div>
                    <div className="mt-md-1">
                      <center>
                        <img
                          src="./images/partners/phonepay.png"
                          className="mt-4"
                          height={70}
                          alt=""
                        />
                        <br />
                        <span
                          style={{
                            color: "#0b76bc",
                          }}
                        >
                          Fonepay Payment Service Limited
                        </span>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* powered and initiatives */}

            <div className="powered container second_last pt-0 pt-lg-5 pb-5">
              <div className="row w-100 ">
                <div className="col-md-3 col-lg-3 pt-5 pt-md-0">
                  <div className="partners-head pb-2">
                    <h3>Global Business Partner</h3>
                  </div>
                  <div className="">
                    <center>
                      <img
                        src="./images/partners/indrive.png"
                        className=" indrive p-4"
                        alt=""
                      />
                      <br />
                      <span
                        style={{
                          color: "#0b76bc",
                        }}
                      >
                        inDrive
                      </span>
                    </center>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div className="partners-head pb-3 pt-5 pt-md-0">
                    <h3>Non-Life Insurance Partner</h3>
                  </div>
                  <div>
                    <div>
                      <center>
                        <img
                          src="./images/partners/ipartner.png"
                          className="sanima"
                          alt=""
                        />
                        <br />
                        <span
                          style={{
                            color: "#0b76bc",
                          }}
                        >
                          Sanima GIC Insurance
                        </span>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div className="partners-head pb-3 pt-5 pt-md-0">
                    <h3>Knowledge Partner</h3>
                  </div>
                  <div>
                    <div>
                      <center>
                        <img
                          src="./images/partners/bc.png"
                          className="w-100 british"
                          alt=""
                        />
                        <br />
                        <span
                          style={{
                            color: "#0b76bc",
                          }}
                        >
                          The British College
                        </span>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div className="partners-head pt-lg-0 pt-4 pb-1">
                    <h3>Banking Partner</h3>
                  </div>
                  <div>
                    <div className="pt-lg-2">
                      <center>
                        <img
                          src="./images/partners/nbl.jpg"
                          alt=""
                          className="sanima "
                        />
                        <br />
                        <span
                          style={{
                            color: "#0b76bc",
                          }}
                        >
                          Nepal Bank Limited (NBL)
                        </span>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="powered container pt-0 pt-lg-5 pb-5 last">
              <div className="row w-100 ">
                <div className="col-md-3 col-md-3 pt-5 pt-md-0">
                  <div className="partners-head pb-2">
                    <h3>Health Care Partner</h3>
                  </div>
                  <div className="">
                    <center>
                      <img
                        src="./images/partners/star.jpg"
                        className="star w-100"
                        alt=""
                      />
                      <br />
                      <span
                        style={{
                          color: "#0b76bc",
                        }}
                      >
                        Star Hospital
                      </span>
                    </center>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div className="partners-head pt-lg-0 pt-4 pb-1">
                    <h3>Airlines Partner</h3>
                  </div>
                  <div>
                    <div className="pt-lg-2">
                      <center>
                        <img
                          src="./images/partners/nepalairlines.png"
                          alt=""
                          className="mt-4 arilines"
                        />
                        <br />
                        <span
                          style={{
                            color: "#0b76bc",
                          }}
                        >
                          Nepal Airlines Corporation (NAC)
                        </span>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="partners-head pt-lg-0 pt-4 pb-1">
                    <h3>Development Banking Partner</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="pt-lg-2">
                        <center>
                          <img
                            src="./images/partners/sangrila.jpg"
                            alt=""
                            className="w-100 mt-lg-3 "
                          />
                          <br />
                          <span
                            style={{
                              color: "#0b76bc",
                            }}
                          >
                            Shangri-la Development Bank Ltd.
                          </span>
                        </center>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pt-lg-3 mt-3 mt-lg-0 mt-md-0">
                        <center>
                          <img
                            src="./images/partners/mahalaxmi.png"
                            alt=""
                            className="w-100 mt-lg-3 "
                          />
                          <br />
                          <span
                            style={{
                              color: "#0b76bc",
                            }}
                          >
                            Mahalaxmi Development Bank Ltd.
                          </span>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* logo sponsor */}
            <div className="powered pt-5 pt-lg-0 container lps second_last pt-0 pt-lg-5 pb-5">
              <div className="partners-head pb-lg-2">
                <h3>Logo Sponsors</h3>
              </div>
              <div className="row w-100 pt-lg-3 ">
                <div className="col-md-3 col-lg-3 pt-3 pt-md-3">
                  <div className="">
                    <center>
                      <img
                        src="./images/partners/krishi.jpg"
                        className="krishi"
                        alt=""
                      />
                      <br />
                      <span
                        style={{
                          color: "#0b76bc",
                        }}
                      >
                        Agricultural Development Bank Ltd.
                      </span>
                    </center>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div>
                    <center>
                      <img
                        src="./images/partners/info.png"
                        className="info w-100 p-3 m-3"
                        alt=""
                      />
                      <br />
                      <span
                        style={{
                          color: "#0b76bc",
                        }}
                      >
                        Info Developers
                      </span>
                    </center>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div>
                    <div>
                      <center>
                        <img
                          src="./images/partners/sanima.png"
                          className="w-100 pt-lg-5 p-3 mt-3 mt-lg-4"
                          alt=""
                        />
                        <br />
                        <span
                          style={{
                            color: "#0b76bc",
                          }}
                        >
                          Sanima Bank Ltd.
                        </span>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div>
                    <div className="pt-lg-2">
                      <center>
                        <img
                          src="./images/partners/nmb.jpg"
                          alt=""
                          className="w-100 nmb mt-2 mt-lg-4"
                        />
                        <br />
                        <span
                          style={{
                            color: "#0b76bc",
                          }}
                        >
                          NBM Bank Ltd.
                        </span>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="powered container pt-5 pb-5">
              <div className="row w-100">
                <div className="col-md-4 col-lg-4">
                  <div className="partners-head pb-3 pt-md-0">
                    <h3>Academic Partner</h3>
                  </div>
                  <div>
                    <div>
                      <center>
                        <img
                          src="./images/website/academic.png"
                          alt=""
                          style={{
                            height: "100px",
                          }}
                        />
                      </center>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-lg-8">
                  <div className="partners-head pb-3 pt-4 pt-md-0 pb-md-4">
                    <h3>Development Banking Partner</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-lg-4">
                      <center>
                        <img
                          src="./images/website/mahalaxmi.jpg"
                          className="w-100 mt-md-2 pt-4 pt-md-0 pimg "
                          alt=""
                        />
                      </center>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <center>
                        <img
                          src="./images/partners/garima.jpg"
                          className="w-100 mb-4 mb-md-0 mb-lg-0 pimg"
                          alt=""
                        />
                      </center>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <center>
                        <img
                          src="./images/partners/kamana.jfif"
                          className="w-100 mt-md-2 pimg "
                          alt=""
                        />
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="powered last container pt-5 pb-5">
              <div className="row w-100">
                <div className="col-md-3 col-lg-3">
                  <div className="partners-head pb-3 ">
                    <h3>Startup Community Partner</h3>
                  </div>
                  <div>
                    <div>
                      <center>
                        <img
                          src="./images/website/last1.png"
                          alt=""
                          className="mt-2"
                          style={{
                            height: "90px",
                          }}
                        />
                      </center>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div className="partners-head pb-3 pt-5 pt-md-0">
                    <h3>Insurance Partner</h3>
                  </div>
                  <div>
                    <div>
                      <center>
                        <img
                          src="./images/website/last2.jpg"
                          className="w-100 pimg mt-2"
                          alt=""
                          style={{
                            marginTop: "-15px",
                          }}
                        />
                      </center>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div className="partners-head  pb-3 pt-4 pt-md-0 ">
                    <h3>Supported By</h3>
                  </div>
                  <div>
                    <div>
                      <center>
                        <img
                          src="./images/krishi.jpg"
                          height={"130px"}
                          className=""
                          alt=""
                        />
                      </center>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3">
                  <div className="partners-head pb-3 ">
                    <h3>Beverage Partner</h3>
                  </div>
                  <div>
                    <div>
                      <center>
                        <img
                          src="./images/website/bev.png"
                          alt=""
                          className=""
                          style={{
                            height: "120px",
                          }}
                        />
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <Footer />
          </div>
        </>
      ) : (
        <Welcome />
      )}
    </>
  );
};

export default Home;
