import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { RiArrowRightSLine } from "react-icons/ri";
import "./style.css";

const PastNyesummit = () => {
  return (
    <div>
      <Header />

      <div className="Organizing container">
        <img src="./images/h2.jpg" className="w-100" alt="" />
      </div>
      <Footer />
    </div>
  );
};

export default PastNyesummit;
