import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { RiArrowRightSLine } from "react-icons/ri";
import "./style.css";

const AboutNye = () => {
  return (
    <div>
      <Header />
      <div className="inner-hero ">
        <div className="parallalx">
          <div className="parallal-content">
            <h4 className="ps-2 pe-2">
              Summit 2023 <RiArrowRightSLine />
              About NYE (Nepal Youth Entrepreneurship) Summit
            </h4>
          </div>
        </div>
      </div>
      <div className="Organizing pt-5 pb-5">
        <div className="advisory-committee pt-3 mt-2 pb-4">
          <div className="acommittee">
            <div className="advisory-committed_head pb-5">
              <h2>About the Summit</h2>
            </div>
            <div className="about-text">
              <div className="container">
                <div className="row">
                  <div>
                    <p>
                      Nepal Youth Entrepreneurship Summit (NYES) 2023 marks an
                      important milestone in the roadmap towards boosting an
                      ecosystem for entrepreneurs in Nepal. This highly
                      interactive event will provide information to the audience
                      on how to transform ideas to successful business,
                      challenges and opportunities for entrepreneurs in Nepal,
                      insights from industry veterans, governments’ take on
                      innovative businesses and various such topics pertaining
                      to the business environment in Nepal. This is a follow-up
                      summit to the first Nepal Youth Entrepreneurship Summit
                      held on February 14, 2019, and second Nepal Youth
                      Entrepreneurship Summit held on September 11, 2022
                      inaugurated by current Prime Minister of Nepal, The Right
                      Honorable Pushpa Kamal Dahal.
                      <br />
                      <br />
                      Nepal Youth Entrepreneurship Summit (NYES) 2023 with the
                      theme of <b>
                        "Youth Innovation, Investment and Growth"
                      </b>{" "}
                      will be jointly organized by Nepal Government Youth and
                      Sports Ministry, National Youth Council, along with the
                      Bagmati Province Government and Nepal Business Institute
                      (NBI). Third edition of this summit is being held on
                      September 16 th , 2023 in Kathmandu. The summit includes
                      high-level dialogue on the development, promotion and
                      expansion of youth entrepreneurship, experience sharing,
                      story-sharing, group discussions, networking, award
                      distribution with presentations of successful young
                      entrepreneurs, entrepreneurs&#39; walk, exhibition of
                      innovative work being done in the world market, investment
                      agreements and other programs. Summit has been designed as
                      a unique platform to create youth success, potential and
                      opportunities. Third NYE summit helps to make entrepreneur
                      friendly environment, give confidence, courage and sense
                      of responsibility to the youth, to scale up innovation and
                      entrepreneurship, to enhance agenda of economic
                      prosperity, to attract youth on subsidy and investment
                      model of the government.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="acommittee">
            <div className="advisory-committed_head pb-5 pt-4">
              <h2>Objectives of the Summit </h2>
            </div>
            <div className="about-text">
              <div className="container">
                <p>
                  Nepal Youth Entrepreneurship Summit 2023 will inspire youth to
                  become entrepreneurs in the productive sector in the country
                  for a self-sufficient economy. This summit will be an
                  achievement for the construction and implementation of the
                  <b> Youth Entrepreneurship Vision 2023</b>. It is expected
                  that this summit is helpful to encourage youth to become
                  entrepreneurs, facilitate startup enterprises, make government
                  policies, programs and budgets youth-entrepreneurship friendly
                  and achieve the community through youth entrepreneurship in
                  Nepal.
                </p>
                <div className="row">
                  <div className="col-md-7 col-lg-7">
                    <ul className="objectives">
                      <li>
                        To find solutions on the latest economic crisis and to
                        identify the priority areas of the investment for
                        promoting youth entrepreneurship and attract young
                        people to start-up enterprise.
                      </li>
                      <li>
                        For high-level networking and investment deals between
                        young people who want to become entrepreneurs and
                        investors who are investing in startups.
                      </li>
                      <li>
                        To conduct workshops on youth financing, government
                        policies, startup programs, story sharing, group
                        discussions and startup business in different sessions
                        to increase the awareness of young entrepreneurs and
                        facilitate business scale.
                      </li>
                      <li>
                        Increase the meaningful participation and awareness of
                        youth in achieving the goals of sustainable development
                        to develop a responsible business culture.
                      </li>
                      <li>
                        To re-orient the government for the current economic
                        challenges and way forward.
                      </li>
                      <li>
                        Protecting large industries, encouraging small, medium
                        and startup enterprises.
                      </li>
                      <li>
                        To organize an exhibition of indigenous products and
                        innovative services produced by young entrepreneurs.
                      </li>
                      <li>
                        To mentor the youth and to respect and encourage the
                        successful industries run by the youth.
                      </li>
                      <li>
                        To review the policies, programs and budgets made by the
                        three levels of government for youth enterprises as
                        required in the law.
                      </li>
                      <li>
                        To make appropriate arrangements in monetary policy for
                        bank access to youth enterprises and to make positive
                        about youth entrepreneurship institutions like Nepal
                        Rastra Bank, Nepal Bankers Association, CBFIN and
                        others.
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-5 col-md-5">
                    <img
                      src="./images/3X9A4245.JPG"
                      className="w-100"
                      alt="about_image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="acommittee">
            <div className="advisory-committed_head pb-5 pt-4">
              <h2>Rationale of the Summit </h2>
            </div>
            <div className="about-text">
              <div className="container">
                <p>
                  Sustainable economic growth becomes the agenda of both
                  government and private sector at present. Entrepreneurship and
                  innovation have received enough emphasis from all three tiers
                  of government. Government must utilize the demographic benefit
                  to strengthen entrepreneurship and innovations. It is
                  important for a country&#39;s economic progress to make youth
                  self-sufficient and employable. In contrast, the main factors
                  that slow down the growth of new businesses include a lack of
                  favourable investment environments for young people, a lack of
                  knowledge and resources, legal difficulties, a lack of
                  cohesive programs, and a lack of implementation. In response
                  to this, the 2023 Nepal Youth Entrepreneurship Summit is going
                  to be held. Participants from all 77 districts will engage in
                  dialogue with youths about the opportunities and challenges of
                  youth entrepreneurship, award inspiring youth entrepreneurs,
                  display the products of youth entrepreneurs from across Nepal,
                  and put pressure on the government to develop youth-friendly
                  policies, programs, and initiatives.
                </p>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              width: "90%",
              margin: "auto",
            }}
          >
            <div className="acommittee col-lg-6">
              <div className="advisory-committed_head pb-5 pt-4">
                <h2>Key Reasons to Partnership / Attend this Summit</h2>
              </div>
              <div className="about-text">
                <div className="container">
                  <p>
                    Nepal Youth Entrepreneurship Summit is the biggest summit of
                    the Entrepreneurship Sector.
                  </p>
                  <ul className="objectives">
                    <li>Visibility and Branding</li>
                    <li>Relevant Thematic session</li>
                    <li>Learning and Mentoring</li>
                    <li>Stronger Business Networking</li>
                    <li>Exhibition</li>
                    <li>Effective Youth Engagement in SDGs</li>
                    <li>Fostering Youth Entrepreneurship</li>
                    <li>Seed Founding Opportunities</li>
                    <li>Financial Literacy and Access to Finance</li>
                    <li>Connect Your Business in Youth Community</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="acommittee col-lg-6">
              <div className="advisory-committed_head pb-5 pt-4">
                <h2>Key highlights of the Entrepreneurship Summit</h2>
              </div>
              <div className="about-text">
                <div className="container">
                  <ul className="objectives">
                    <li>500 + Youth Delegates</li>
                    <li>30+ National and international speaker</li>
                    <li>9+ amazing Sessions</li>
                    <li>10+ Successful Startup Presentation and Awards</li>
                    <li>60+ Districts</li>
                    <li>30+ Mentor</li>
                    <li>100+ Startup Investor</li>
                    <li>60+ Media Facebook live and news Coverage</li>
                    <li>Innovative Startup showcase</li>
                    <li>Drone Activities and Innovative videos</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              width: "90%",
              margin: "auto",
            }}
          >
            <div className="acommittee col-lg-6">
              <div className="advisory-committed_head pb-5 pt-4">
                <h2>In particular, the campaign will focus on:</h2>
              </div>
              <div className="about-text">
                <div className="container">
                  <ol className="objectives">
                    <li>
                      Dedicated strategies for the promotion of youth
                      entrepreneurship and self- employment to create an
                      enabling regulatory environment.
                    </li>
                    <li>
                      The role of networks, peer-to-peer support, access to
                      information and access to technology in facilitating
                      success for youth-led enterprises
                    </li>
                    <li>
                      Access to finance and its crucial role in boosting viable
                      and sustainable business and jobs; and
                    </li>
                    <li>
                      The opportunities available from support systems tailored
                      to the business cycle.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="acommittee col-lg-6">
              <div className="advisory-committed_head pb-5 pt-4">
                <h2>Delegates</h2>
              </div>
              <div className="about-text">
                <div className="container">
                  <ul className="objectives">
                    <li>200 Youth/Students</li>
                    <li>100 Startup Entrepreneurs</li>
                    <li>75 Startup Investors</li>
                    <li>
                      50 Professionals, including Government Officials,
                      Politicians, Law Makers, Professors
                    </li>
                    <li>
                      60 Senior Entrepreneurs from Banking and Financial
                      Organizations
                    </li>
                    <li>25 Media Representatives</li>
                    <li>
                      40 Key relevant Stakeholders from different Organizations
                    </li>
                  </ul>
                </div>
                <h4>The Summit is anticipated to draw 525 people in total.</h4>
              </div>
            </div>
          </div>
          <div className="acommittee">
            <div className="advisory-committed_head pb-5 pt-4">
              <h2>Expected outcomes of the Summit</h2>
            </div>
            <div className="about-text neyoutcome">
              <div className="container">
                <h5>
                  The summit is organized to encourage youth to become
                  entrepreneurs in NYES 2023, to facilitate start-up
                  enterprises, to make government policies, programs and budgets
                  youth entrepreneurship friendly and to achieve prosperity
                  through youth entrepreneurship of Nepal by preparing Youth
                  Entrepreneurship Vision 2023 for implementation.
                </h5>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <ul className="objectives">
                      <li>
                        The summit will facilitate the successful implementation
                        of youth-friendly policies, programs and budgets.
                      </li>
                      <li>
                        In the presence of the government and banks and
                        financial institutions at the venue of the conference,
                        youth enterprise registration and consultation centers
                        and assistance desks for subsidized loan from financial
                        institutions will be established to facilitated
                        mentorship and subsidized loans for enterprise
                        registration and operation.
                      </li>
                      <li>
                        Direct one-to-one business talks between start-up
                        investors and young entrepreneurs who want to expand
                        (scale up) the industry will help to increase investment
                        in the startup industry.
                      </li>
                      <li>
                        Through Udhami Mela, Nepali products and innovative
                        services produced by the youth will be supported
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <ul className="objectives">
                      <li>
                        Entrepreneurs who are doing good work and have earned a
                        lot of profit will be encouraged through exposure and
                        awards.
                      </li>
                      <li>
                        Young people will be attracted for enterprise/business
                        in the country. In the summit youth can get High level
                        networking
                      </li>
                      <li>
                        It is expected that all 3 levels of government and banks
                        and financial institutions will be positive for
                        investment in Yuva Udham.
                      </li>
                      <li>
                        Workshops on youth access to finance, government policy,
                        financial policy, startup program, story sharing, group
                        discussion, and startup business will help in capacity
                        building and business scaling of young entrepreneurs.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutNye;
