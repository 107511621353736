import React from "react";
import "./style.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const PastSponsors = () => {
  return (
    <div>
      <Header />
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center text-white">Past Sponsors</h2>
        </div>
      </div>
      <div className="sponsors">
        <h3>Government Sector</h3>
        <div className="container">
          <div className="spon-card">
            <div className="img">
              <img src="./images/sarkar.jpg" alt="" />
            </div>
            <p>
              Government of Nepal <br />
              <span>Ministry of Industry, Commerce & Supplies</span>
            </p>
          </div>
          <div className="spon-card">
            <div className="img">
              <img src="./images/sarkar.jpg" alt="" />
            </div>
            <p>
              Government of Nepal <br />
              <span>Minstry of Youth and Sports</span>
            </p>
          </div>
          <div className="spon-card">
            <div className="img">
              <img src="./images/sarkar.jpg" alt="" />
            </div>
            <p>
              Government of Nepal <br />
              <span>Minstry of Youth and Sports</span>
            </p>
          </div>
          <div className="spon-card">
            <div className="img">
              <img src="./images/sarkar.jpg" alt="" />
            </div>
            <p>
              Province Government <br />
              <span>Minstry of Tourism Industry and Cooperatives</span>
              Bagmati Province
            </p>
          </div>
          <div className="spon-card">
            <div className="img">
              <img src="./images/sarkar.jpg" alt="" />
            </div>
            <p>
              Government of Nepal <br />
              <span>Minstry of Labour, Employment and Social Security</span>
            </p>
          </div>
          <div className="spon-card">
            <div className="img">
              <img src="./images/sarkar.jpg" alt="" />
            </div>
            <p>
              Government of Nepal <br />
              <span>National Planning Commission</span>
            </p>
          </div>
          <div className="spon-card">
            <div className="img nyc">
              <img src="./images/nyc.png" alt="" />
            </div>
            <p>
              <span>National Youth Council</span>
            </p>
          </div>
          <div className="spon-card">
            <div className="img nrb">
              <img src="./images/nrb.png" />
            </div>
            <p>
              <span>Nepal Rastra Bank</span>
            </p>
          </div>
          <div className="spon-card">
            <div className="img cetvt">
              <img src="./images/ctevt.png" alt="" />
            </div>
            <p>
              <span>CTEVT</span>
            </p>
          </div>
          <div className="spon-card">
            <div className="img nta">
              <img src="./images/nta.png" alt="" />
            </div>
            <p>
              <span>Nepal Telecommuniccations Authority</span>
            </p>
          </div>
        </div>
        <h3>Donor Agencies</h3>
        <div className="container donor">
          <div className="spon-card">
            <div className="img">
              <img src="./images/undp.jpg" alt="" />
            </div>
          </div>
          <div className="spon-card">
            <div className="img">
              <img src="./images/neighbour.jpg" alt="" />
            </div>
          </div>
          <div className="spon-card">
            <div className="img ">
              <img src="./images/plan.png" alt="" className="pt-3" />
            </div>
          </div>
          <div className="spon-card">
            <div className="img">
              <img src="./images/heifer.png" alt="" className="pt-3" />
            </div>
          </div>
          <div className="spon-card">
            <div className="img">
              <img src="./images/wwf.png" alt="" height={100} />
            </div>
          </div>
        </div>
        <h3>Private Sector</h3>
        <div className="container donor">
          <div className="spon-card">
            <div className="img">
              <img src="./images/worldlink.png" alt="" className="pt-4" />
            </div>
          </div>
          <div className="spon-card">
            <img src="./images/ime.png" alt="" className="pt-4" />
          </div>
          <div className="spon-card">
            <img
              src="./images/bishalfoundation.png"
              className="p-2 pt-4"
              alt=""
            />
          </div>
          <div className="spon-card">
            <img src="./images/nli.jpg" alt="" />
          </div>
          <div className="spon-card">
            <img src="./images/agbank.jpg" className="pt-1" alt="" />
          </div>
          <div className="spon-card">
            <img src="./images/dishhome.png" className="pt-1" alt="" />
          </div>
          <div className="spon-card">
            <img src="./images/ntc.png" className="pt-1" height={125} alt="" />
          </div>
          <div className="spon-card">
            <img src="./images/presidential.png" className="pt-4" alt="" />
          </div>
          <div className="spon-card">
            <img src="./images/imepay.png" className=" p-2 pt-3" alt="" />
          </div>
          <div className="spon-card">
            <img
              src="./images/chandragigi.png"
              height={100}
              className="pt-1"
              alt=""
            />
          </div>
          <div className="spon-card">
            <img src="./images/kamana.png" className="p-2 pt-4" alt="" />
          </div>
          <div className="spon-card">
            <img
              src="./images/nagarik.jpg"
              height={110}
              className="pt-1"
              alt=""
            />
          </div>
          <div className="spon-card">
            <img src="./images/uniglobe.png" className="p-2 pt-4" alt="" />
          </div>
          <div className="spon-card">
            <img src="./images/info.png" className="p-2 " alt="" />
          </div>
          <div className="spon-card">
            <img src="./images/rbb.jpg" className="pt-1" alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PastSponsors;
