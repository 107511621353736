import axios from "axios";

export const getStalls = async () => {
  return await axios.get(`/api/stalls`);
};

export const getStall = async (id) => {
  return await axios.get(`/api/stall/${id}`);
};

export const updateViewsStatus = async (id, isView, authtoken) => {
  return await axios.put(`/api/updatestallView/${id}`, isView, {
    headers: {
      authtoken,
    },
  });
};
export const updateBookStatus = async (id, isBooked, authtoken) => {
  return await axios.put(
    `/api/updateBooked/${id}`,
    { isBooked: isBooked },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const updatesPaidStatus = async (id, isPaid, authtoken) => {
  return await axios.put(
    `/api/updatesPaid/${id}`,
    { isPaid: isPaid },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const createStall = async (stall, authtoken) => {
  return await axios.post(`/api/stall`, stall, {
    headers: {
      authtoken,
    },
  });
};
