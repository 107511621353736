import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { RiArrowRightSLine } from "react-icons/ri";
import "./style.css";

const AboutOrg = () => {
  return (
    <div>
      <Header />
      <div className="inner-hero ">
        <div className="parallalx">
          <div className="parallal-content">
            <h4 className="ps-2 pe-2">
              Summit 2023 <RiArrowRightSLine />
              About The Organizer
            </h4>
          </div>
        </div>
      </div>
      <div
        className="moder distinguished-guest pt-3 pb-md-5 pt-md-4 pb-4"
        id="organizer"
      >
        <div className="moderator p-4 pt-md-5">
          <h3 className="pb-md-3">Organizer</h3>
        </div>
        <div className="guest pb-md-4">
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/surendra.jpeg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <div className="card-name mt-2">
                  <h4>Mr. Surendra Basnet</h4>
                </div>
                <div className="card-position">
                  <p>
                    Vice Chairperson, National Youth Council, GoN (Program
                    Advisor)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/moderator/bishalsir.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/moderator/nbis.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Bishal Gaire</h4>
                </div>
                <div className="card-position">
                  <p>Entrepreneur/ Chairman, NBI</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/arjun.jpeg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                {/* <img
                  src="./images/moderator/cbfin.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                /> */}
                <div className="card-name mt-2">
                  <h4>Mr. Arjun Bista</h4>
                </div>
                <div className="card-position">
                  <p>Member</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/sarita.jpeg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title ">
                <div className="card-name ">
                  <h4>Ms. Sarita Sapkota </h4>
                </div>
                <div className="card-position">
                  <p>Member</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/dipisha.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <div className="card-name">
                  <h4>Ms. Dipisha Ale </h4>
                </div>
                <div className="card-position">
                  <p>Member</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="guest pb-md-4">
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/narayan.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title ">
                <div className="card-name">
                  <h4>Mr. Narayan Dhungana</h4>
                </div>
                <div className="card-position">
                  <p>Member</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/arjunn.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <div className="card-name mt-2">
                  <h4>Mr. Arjun Neupane</h4>
                </div>
                <div className="card-position">
                  <p>Member</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/anil.jpeg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <div className="card-name mt-2">
                  <h4>Mr. Anil Shrestha</h4>
                </div>
                <div className="card-position">
                  <p>Member</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/saphal.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                {/* <img
                  src="./images/moderator/cbfin.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                /> */}
                <div className="card-name mt-2">
                  <h4>Mr. Saphal Subedi</h4>
                </div>
                <div className="card-position">
                  <p>Member</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/rajkumar.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <div className="card-name ">
                  <h4>Mr. Raj Kumar Adhikari</h4>
                </div>
                <div className="card-position">
                  <p>Member</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="organizer_profile p-3 p-md-0 ">
        <h2>Organizer Profile</h2>
        <div className="col-md-8 offset-md-2 pb-3">
          <div className="organizers row">
            <div className="col-md-7 pt-lg-4">
              <div className="otext">
                <p className="ptext">
                  The Constitution of Nepal states that through the
                  identification, protection, promotion and dissemination of
                  Nepal's historical, cultural, religious, archaeological and
                  natural heritage, the development of eco-friendly tourism
                  industry as an important basis of the national economy, the
                  creation of the necessary environment and policies for the
                  development of tourism culture, and the distribution of the
                  benefits of the tourism industry. There is a tourism policy
                  that gives priority to local people.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="logo p-3">
                <img src="./images/sarkar.jpg" alt="" />
                <p className="logo-text">
                  Province Government <br />
                  <span>
                    {" "}
                    Ministry of Tourism Industry and Cooperatives{" "}
                  </span>{" "}
                  <br />
                  Bagamati Province
                </p>
                <a href="https://www.mocat.bagmati.gov.np" target="__blank">
                  www.mocat.bagmati.gov.np
                </a>
              </div>
            </div>
          </div>
          <div className="organizers oneo row mt-4">
            <div className="col-md-7 pt-lg-4">
              <div className="otext">
                <p className="ptext">
                  According to the National Youth Council Act 2072, the
                  Government of Nepal has established the National Youth Council
                  as a central government agency for the development and
                  mobilization of all Nepali youth, as reflected in the National
                  Youth Policy 2072 and Youth Vision 2025 and the ten-year
                  strategic plan.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="logo p-3">
                <img src="./images/partners/nyc.jpg" alt="" />
                <p className="logo-text">
                  <span> National Youth Council</span> <br />
                </p>
                <a href="https://www.nyc.gov.np" target="__blank">
                  www.nyc.gov.np
                </a>
              </div>
            </div>
          </div>
          <div className="organizers row mt-4">
            <div className="col-md-7 pt-lg-4">
              <div className="otext">
                <p className="ptext">
                  The Nepal Business Institute (NBI) is an academic institution
                  that takes the lead in fostering entrepreneurship and business
                  environment at the national level. NBI is founded with the
                  core motto “Learning Business, Inspiring Growth” and strives
                  to plan Nepal’s economic development through entrepreneurship
                  and innovation.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="logo p-3">
                <img src="./images/partners/nbi.png" alt="" />
                <p className="logo-text">
                  <span>Nepal Business Institute</span> <br />
                </p>
                <a
                  href="https://www.nepalbusinessinstitute.com"
                  target="__blank"
                >
                  www.nepalbusinessinstitute.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutOrg;
