import React from "react";
import "./style.css";

const Welcome = () => {
  return (
    <div>
      <div className="loading">
        <center>
          <div className="col-md-2">
            <img src="./images/logo.png" className="w-100 welcome_img" alt="" />
          </div>
        </center>
        <div className="welcomes pt-5">
          <h2>Welcome To Nepal Youth Entrepreneurship Summit</h2>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
