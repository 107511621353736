import React from "react";
import NavHead from "./nav/NavHead";
import NavLink from "./nav/NavLink";

import "./dashboard.css";
const Dashboard = () => {
  return (
    <div className="dash">
      <div className="row dash-row">
        <div className="col-md-2 dash-nav">
          <NavLink />
        </div>
        <div className="col-md-10 dash-details">
          <NavHead />
          <hr />
          <div className="dash-screen">
            <div className="allthings">
              <div className="event-block">
                <p className="tenp">
                  1000<sup>+</sup>
                  <br />
                  <span>Delegates</span>
                </p>
              </div>
              <div className="event-block">
                <p className="tenp">
                  700<sup>+</sup>
                  <br />
                  <span>Stalls</span>
                </p>
              </div>
              <div className="event-block">
                <p className="tenp">
                  50<sup>+</sup>
                  <br />
                  <span>Speakers</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
