import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { RiArrowRightSLine } from "react-icons/ri";
import "./style.css";

const AboutNepal = () => {
  return (
    <div>
      <Header />
      <div className="inner-hero ">
        <div className="parallalx">
          <div className="parallal-content">
            <h4 className="ps-2 pe-2">
              About Nepal <RiArrowRightSLine />
              Nepal Profile
            </h4>
          </div>
        </div>
      </div>
      <div className="containers anpl row pt-5 pb-5">
        <div className="col-md-7">
          <div className="nepal_details">
            <p>
              <span> Nepal Profile </span>
              Between India &amp; Tibet Autonomous Region of the People’s
              Republic of China.
            </p>
            <p>
              <span>Area:</span>
              1, 47, 181 sq. km, stretching 885 km from east to west and 193 km
              north to south.
            </p>
            <p>
              <span>Population: </span>  Approx. 29 million with absent
              population of 2.2 million and growth rate of 0.92% per annum (As
              per census 2021)
            </p>
            <p>
              <span>Latitude &amp; Longitude:</span>
               26’22’ and 30’27’ North &amp; 80′ 4′ and 88′ 12′ East
            </p>
            <p>
              <span>Capital city: </span>Kathmandu — Population of about 2041578
              (As per census 2021) in the District itself. Believed to be
              approximately 3.1 million in the entire Kathmandu valley.
            </p>
            <p>
              <span>Peoples: </span>There are 125 caste/ethnic groups reported
              in the census 2011. Chhetri is the largest caste/ethnic groups
              having 16.6% (4,398,053) of the total population followed by
              Brahman-Hill (12.2%; 3,226,903), Magar (7.1% ; 1,887,733), Tharu
              (6.6% ; 1,737,470), Tamang (5.8% ; 1,539,830), Newar (5% ;
              1,321,933), Kami (4.8% ; 1,258,554), Musalman (4.4% ; 1,164,255),
              Yadav (4% ; 1,054,458) and Rai (2.3% ; 620,004).
            </p>
            <p>
              <span>Language(s): </span>
              There are 123 languages spoken as mother tongue reported in census
              2011. Nepali is spoken as mother tongue by 44.6 percent
              (11,826,953) of the total population followed by Maithili (11.7%
              3,092,530), Bhojpuri (5.98%; 1,584,958), Tharu (5.77%; 1,529,875),
              Tamang (5.11%; 1,353,311), Newar (3.2%; 846,557), Bajjika (2.99%;
              793,418), Magar (2.98%; 788,530), Doteli (2.97%; 787,827), Urdu
              (2.61%; 691,546).
            </p>
            <p>
              <span>Religion(s): </span>
              There are ten religious categories reported in the census 2011.
              Hindu is followed by 81.3 percent (21,551,492) of the population
              followed by Buddhism (9%; 2,396,099), Islam (4.4%; 1,162,370),
              Kirat (3.1%; 807,169), Christianity (1.4%; 375,699), Prakriti
              (0.5%; 121,982), Bon (13,006), Jainism (3,214), Bahai (1,283) and
              Sikhism (609).
            </p>
            <p>
              <span>Topography: </span>
              From the World’s Deepest gorge ‘Kali-Gandaki’ to the highest point
              on earth, the Mt. Everest at 8848.86m.
            </p>
            <p>
              <span>Vegetation: </span>
              Nepal possesses some of the most outstanding bio-diversity in the
              world, ranging from sub-tropical Rain-forests to Alpine Deserts.
            </p>
            <p>
              <span>Climate: </span>
              Climate ranges from sub-tropical in the low lands to Arctic higher
              altitudes.
            </p>
            <p>
              <span>Seasons: </span>
              Winter (December – February) <br />
              Summer (March – May ) <br />
              Monsoon (June – August) <br />
              Autumn (September – November)
            </p>
            <p>
              <span>National Bird &amp; National Flower:</span>
              Impean Pheasant (Danfe) &amp; Rhododendron Arboreum (Lali Gurans)
            </p>
            <p>
              <span>National Festivals:</span>
              Dashain, Tihar, Shivaratri, Nepali new year (Baishak first), Teej,
              Chhath etc.
            </p>
            <p>
              <span>World Heriatge Sites: </span>
              In Nepal, there are two UNESCO world heritage site respectively
              cultural world heritage site and another is natural world
              heritage. UNESCO cultural world heritage site have eight site
              respectively Kathmandu Durbar Square, Patan Durbar Square,
              Bhaktapur Durbar Square, Swayambhunath, Boudhanath, Pashupatinath,
              Changunarayan and Lumbini. Seven Cultural World Heritage Sites are
              situated within 20 km of radius, in the Kathmandu Valley except
              Lumbini. Another UNESCO natural world heritage site including two
              site respectively Sagarmatha National Park (Mt. Everest National
              Park) and Chitwan National Park.
            </p>
            <p>
              <span>Flora and Fauna: </span>
              840 different species of wet-land, migratory and residential bird,
              8% of the world’s population of birds.
            </p>
            <p>
              <span>Home to Endangered Species Like: </span>
              Royal Bengal Tiger, One horned rhino, Snow – Leopard, Red Panda,
              Brown Bear, Assamese Macaque, Gangetic Dolphin, Wolf, Wild
              Elephant, Great Hornbill, Swamp Deer, Wild Yak, Tibetan Antelope,
              Black Buck, Four Horned antelope, Musk Deer, Pigmy Hog, Haspid
              Hare, Pangolin, Gharial, Indian Bustart, Saras Crane, Impean
              Pheasant, Python etc.
            </p>
            <p>
              <span>A Living Cultural Heritage: </span>
              Nepal’s Heritage is alive and dynamic. Shamanism, Pan-animism
              &amp; Witch-craft are still practiced in remote regions. Temples,
              shrines, monuments and monasteries are extremely active with
              devotees burning butter-lamps, singing hymns, chiming temple bells
              and playing drums. The only country that has living goddess
              “Kumari”
            </p>
            <p>
              <span>Currency: </span>
               Nepalese Rupee (NPR).
            </p>
            <p>
              <span>Political-System: </span>
              Federal Democratic Republic.
            </p>
            <p>
              <span>Government: </span>
              Constitutionally, Nepal is a parliamentary democracy with
              ceremonial President as Head of State and a Prime Minister as Head
              of Government.
            </p>
            <p>
              <span>Head of State: </span>
               Rt. Hon. President Mr. Ramchandra Paudel (elected on March 13,
              2023)
            </p>
            <p>
              <span>Head of Government: </span>
              Rt. Hon. Prime Minister Mr. Pushpa kamal dahal ‘Prachanda’
              (elected on December 25, 2022)
            </p>
            <p>
              <span>
                Membership International/Regional Organisations/Groups: 
              </span>
              United Nations, Non-Aligned Movement, South Asian Association for
              Regional Co-operation (SAARC), Bay of Bengal Initiative for
              Multi-Sectoral Technical and Economic Cooperation (BIMSTEC), Asia
              Cooperation Dialogue (ACD).
            </p>
          </div>
        </div>
        <div className="col-md-5">
          <div className="images_title">
            <img src="./images/flag.avif" alt="" className="p-3 w-100" />
            <h5 className="text-center">Flag of Nepal</h5>
          </div>
          <div className="images_title">
            <img src="./images/pasupati.jpg" alt="" className="p-3 w-100" />
            <h5 className="text-center">Pashupti Nath Temple</h5>
          </div>
          <div className="images_title">
            <img src="./images/mte.jpg" alt="" className="p-3 w-100" />
            <h5 className="text-center">Mt. Everest</h5>
          </div>
          <div className="images_title">
            <img src="./images/lumbini.jpg" alt="" className="p-3 w-100" />
            <h5 className="text-center">
              The Birth Place of Lord Buddha(Lumbini)
            </h5>
          </div>
        </div>
      </div>
      <div className="investment">
        <a
          href="https://mofa.gov.np/about-nepal/investment-in-nepal/"
          target="__blank"
        >
          See Investment In Nepal
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default AboutNepal;
