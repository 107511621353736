import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { RiArrowRightSLine } from "react-icons/ri";
import "./style.css";

const EntreprenerushipNepal = () => {
  return (
    <div>
      <Header />
      <div className="inner-hero ">
        <div className="parallalx">
          <div className="parallal-content">
            <h4 className="ps-2 pe-2">
              Summit 2023 <RiArrowRightSLine />
              Entrepreneurship Nepal
            </h4>
          </div>
        </div>
      </div>
      <div className="ents">
        <div className="pt-5 pb-5">
          <div className="">
            <div className="col-md-8 offset-md-2 entre">
              <h2 className="text-center pt-4 pb-4">Entrepreneurship Nepal</h2>
              <p>
                National Youth Policy 2072 has defined &quot;Youth&quot; as
                those between the ages of 16 and 40. According to the most
                recent census in 2078, most people in Nepal are young, which is
                a demographic advantage for the country. Every year, 4 lakh 50
                thousand young people in Nepal, enter the labor force. Of these,
                about 90% are either unemployed or leave the country to find
                employment overseas. For national economic development, it has
                become imperative to connect education with work, work with
                skills, and skills with employment and production. In the
                current environment, if youth power is challenged into
                entrepreneurship, prosperity can be attained within this
                generation. Making young people independent and self-employed is
                therefore essential. <br />
                <br />
                Youth entrepreneurship can be encouraged by institutional setups
                such as the youth ministry, youth council, self-employment fund,
                and poverty alleviation fund. The private sector and private
                equity fundraising institutions are willing to invest in the
                startup company. Manifestos, Policies, and programs of political
                parties have addressed youth issues. Youths who returned from
                studying abroad founded some sort of business with the
                knowledge, talents, and experience they earned there; some of
                them went on to be exemplary. In addition, government policies,
                initiatives, loans with favorable terms, and subsidies all
                contributed to the rush of young entrepreneurs from rural to
                urban areas. However, the anticipated outcome has not been
                experienced yet due to limited resources. Youth entrepreneurship
                has generally become the center of interest for young people in
                Nepal. With the collaborative effort of government, private
                sector, and donor organizations it is possible to contribute
                youth entrepreneurship to national income.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EntreprenerushipNepal;
