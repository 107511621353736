import React, { useState, useEffect } from "react";
import NavHead from "../../nav/NavHead";
import NavLink from "../../nav/NavLink";
import { getParticipant } from "../../../../function/registration";
import "../../dashboard.css";
import { useParams } from "react-router-dom";
import { getStall } from "../../../../function/stall";

const SingleStall = () => {
  const [stall, setStall] = useState({});
  const [loading, setLoading] = useState(false);

  const params = useParams();
  useEffect(() => {
    loadStall();
  }, []);

  const loadStall = () =>
    getStall(params.id).then((res) => setStall(res.data.participant));
  return (
    <div className="dash-contact row w-100">
      <div className="col-md-2 dash-nav">
        <NavLink />
      </div>
      <div className="col-md-10 dash-details">
        <NavHead />
        <hr />
        <div className="dash-screen">
          <div className="tabs">
            <div className="details">
              <p>
                <span>Name:</span> {stall.name}
              </p>
              <p>
                <span>Address:</span> {stall.address}
              </p>
              <p>
                <span>Contact Number:</span> {stall.phone}
              </p>
              <p>
                <span>Email:</span> {stall.email}
              </p>
              <p>
                <span>Company Name:</span> {stall.category}
              </p>
              <p>
                <span>Designation:</span> {stall.designation}
              </p>
              <p>
                <span>Message:</span> {stall.message}
              </p>
              <p className={stall.isPaid == "Paid" ? "paid" : "notPaid"}>
                <span>Payment Status:</span>{" "}
                {stall.isPaid == "Paid" ? <>Paid</> : <>Not Paid</>}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleStall;
