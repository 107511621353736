import React, { useState, useEffect, useMemo } from "react";
import NavHead from "../../nav/NavHead";
import NavLink from "../../nav/NavLink";
import "../../dashboard.css";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getStalls,
  updateBookStatus,
  updateViewsStatus,
  updatesPaidStatus,
} from "../../../../function/stall";

const AllStalls = () => {
  const [stalls, setStalls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");

  const [selectedPaid, setSelectedPaid] = useState();

  const { token } = useSelector((state) => state.AuthReducer);

  useEffect(() => {
    loadStalls();
  }, []);

  const loadStalls = () =>
    getStalls().then((res) => setStalls(res.data.response));
  const handleChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value.toLowerCase());
  };
  console.log(stalls);
  const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword);

  const updateView = (id) => {
    try {
      updateViewsStatus(id, { isView: true, token });
    } catch (error) {
      console.log(error);
    }
  };
  const handlePaidChange = (id, isPaid) => {
    console.log(isPaid);
    updatesPaidStatus(id, isPaid, token).then((res) => {
      toast.success("Status Updated");
      loadStalls();
    });
  };
  const handleBookedChange = (id, isBooked) => {
    console.log(isBooked);
    updateBookStatus(id, isBooked, token).then((res) => {
      toast.success("Status Updated");
      loadStalls();
    });
  };

  // filter
  function getFilteredList() {
    // Avoid filter when selectedCategory is null
    if (!selectedPaid) {
      return stalls;
    }
    return stalls.filter((item) => item.isPaid === selectedPaid);
  }
  // Avoid duplicate function calls with useMemo
  var filteredList = useMemo(getFilteredList, [selectedPaid, stalls]);

  function handlePaidChanged(event) {
    setSelectedPaid(event.target.value);
  }

  return (
    <div className="dash-contact row w-100">
      <div className="col-md-2 dash-nav">
        <NavLink />
      </div>
      <div className="col-md-10 dash-details">
        <NavHead />
        <hr />
        <div className="dash-screen">
          <div className="search dsearch">
            <input
              type="search"
              placeholder="Filter"
              value={keyword}
              onChange={handleChange}
              className="form-control mb-4"
            ></input>

            <select
              name="category-list"
              id="category-list"
              onChange={handlePaidChanged}
            >
              <option value="">All</option>
              <option value="Not Paid">Not Paid</option>
              <option value="Paid">Paid</option>
            </select>
          </div>
          <div className="dtabs">
            <div className="tabs">
              {loading ? (
                <p>Loading..</p>
              ) : (
                <table className="tables">
                  <thead>
                    <tr>
                      <th scope="col">S.N</th>
                      <th scope="col">Name</th>
                      <th scope="col">Address</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact</th>
                      <th scope="col">View</th>
                      <th scope="col">Payment Status</th>
                      <th scope="col">Booked Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredList.filter(searched(keyword)).map((n, i) => (
                      <tr key={n._id} className={n.isView ? "view" : "notView"}>
                        <td>{i + 1}</td>
                        <td className="title">{n.name}</td>
                        <td className="titles">{n.address}</td>
                        <td className="titles">{n.email}</td>
                        <td className="titles">{n.phone}</td>
                        <td>
                          <Link to={`/stall/${n._id}`}>
                            <button onClick={() => updateView(n._id)}>
                              View
                            </button>
                          </Link>
                          {/* <button onClick={() => updateView(n._id)}>View</button> */}
                        </td>
                        <td>
                          <select
                            name="status"
                            className="form-control"
                            onChange={(e) =>
                              handlePaidChange(n._id, e.target.value)
                            }
                            defaultValue={n.isPaid}
                          >
                            <option value="Not Paid">Not Paid</option>
                            <option value="Paid">Paid</option>
                          </select>
                        </td>
                        <td>
                          <select
                            name="status"
                            className="form-control"
                            onChange={(e) =>
                              handleBookedChange(n._id, e.target.value)
                            }
                            defaultValue={n.isBooked}
                          >
                            <option value="Not Booked">Not Booked</option>
                            <option value="Booked">Booked</option>
                          </select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllStalls;
