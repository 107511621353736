import axios from "axios";

export const getParticipants = async () => {
  return await axios.get(`/api/participants`);
};

export const getParticipant = async (id) => {
  return await axios.get(`/api/participant/${id}`);
};

export const updateViewStatus = async (id, isView, authtoken) => {
  return await axios.put(`/api/updateView/${id}`, isView, {
    headers: {
      authtoken,
    },
  });
};
export const updatePaidStatus = async (id, isPaid, authtoken) => {
  return await axios.put(
    `/api/updatePaid/${id}`,
    { isPaid: isPaid },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const createParticipant = async (participant, authtoken) => {
  return await axios.post(`/api/participant`, participant, {
    headers: {
      authtoken,
    },
  });
};
