import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { RiArrowRightSLine } from "react-icons/ri";
import "./style.css";

const Agenda = () => {
  return (
    <div>
      <Header />
      <div className="inner-hero ">
        <div className="parallalx">
          <div className="parallal-content">
            <h4 className="ps-2 pe-2">Agenda</h4>
          </div>
        </div>
      </div>
      <div className="agendas pt-5 pb-5">
        <div className="agendas">
          <div className="col-md-8 offset-md-2">
            <h2 className="text-center pt-4 pb-4">
              Main Agendas of the Summit
            </h2>
            <p>
              To encourage youth to become entrepreneurs in NYES 2023, to
              facilitate start-ups, to make government policies, programs and
              budgets youth entrepreneurship friendly and to achieve prosperity
              through youth entrepreneurship of Nepal by preparing{" "}
              <b>Youth Entrepreneurship Vision 2023</b> for implementation
              through 3 levels of government a summit will be organized.
              According to which, the following are the initial topics (first
              draft) set for discussion at the High Summit.
            </p>
            <div className="agenda p-5" id="agenda">
              <div className="">
                <div className="row">
                  <div className="col-md-6">
                    <div className="agenda-card mb-3">
                      <div className="d-flex">
                        <h4
                          className="point me-1"
                          style={{ paddingTop: "13px" }}
                        >
                          S1
                        </h4>
                        <h4>Youth Innovation: Investment and Growth</h4>
                      </div>
                    </div>
                    <div className="agenda-carda mb-3">
                      <div className="d-flex">
                        <h4
                          className="point me-1 "
                          style={{ paddingTop: "13px" }}
                        >
                          S2
                        </h4>
                        <h4>
                          Current Economic Challenges and Roadmap for Youth
                          Entrepreneurship
                        </h4>
                      </div>
                    </div>
                    <div className="agenda-cardb mb-3">
                      <div className="d-flex">
                        <h4
                          className="point me-1"
                          style={{ paddingTop: "13px" }}
                        >
                          S3
                        </h4>
                        <h4>Youth Leadership for Prosperity Nepal</h4>
                      </div>
                    </div>
                    <div className="agenda-cardc mb-3">
                      <div className="d-flex">
                        <h4
                          className="point me-1"
                          style={{ paddingTop: "11px" }}
                        >
                          S4
                        </h4>
                        <h4>
                          Potential Investment Sectors of Youth Entrepreneurship
                          for Sustainable Economy
                        </h4>
                      </div>
                    </div>
                    <div className="agenda-cardd mb-3">
                      <div className="d-flex">
                        <h4
                          className="point me-1"
                          style={{ paddingTop: "12px" }}
                        >
                          D1
                        </h4>
                        <h4>Policy Challenges for Startup Business</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="agenda-card mb-3">
                      <div className="d-flex">
                        <h4
                          className="point me-1"
                          style={{ paddingTop: "13px" }}
                        >
                          S5
                        </h4>
                        <h4>
                          Bridging the gap between innovation and success&quot;
                        </h4>
                      </div>
                    </div>
                    <div className="agenda-carda mb-3">
                      <div className="d-flex">
                        <h4
                          className="point me-1"
                          style={{ paddingTop: "11px" }}
                        >
                          S6
                        </h4>
                        <h4>
                          Digital transformation and its impact on business
                          management
                        </h4>
                      </div>
                    </div>
                    <div className="agenda-cardb mb-3">
                      <div className="d-flex">
                        <h4
                          className="point me-1"
                          style={{ paddingTop: "12px" }}
                        >
                          S7
                        </h4>
                        <h4>Dare to Dream</h4>
                      </div>
                    </div>
                    <div className="agenda-cardc mb-3">
                      <div className="d-flex">
                        <h4
                          className="point me-1"
                          style={{ paddingTop: "11px" }}
                        >
                          G1
                        </h4>
                        <h4>
                          Embracing business failures, building an ecosystem for
                          entrepreneurs in Nepal.
                        </h4>
                      </div>
                    </div>
                    <div className="agenda-cardd mb-3">
                      <div className="d-flex">
                        <h4
                          className="point me-1"
                          style={{ paddingTop: "12px" }}
                        >
                          D1
                        </h4>
                        <h4>
                          Made in Nepal: Going local to Global and SDGs: can
                          business also save the world?
                        </h4>
                      </div>
                    </div>
                    <div className="agenda-carde mb-3">
                      <div className="d-flex">
                        <h4 className="point me-1">E2</h4>
                        <h4>
                          Energy Scenario in Nepal: Government and Private
                          Sector Initiatives for its sustainability.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Agenda;
