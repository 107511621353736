import React, { useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import toast from "react-hot-toast";
import { createParticipant } from "../../function/registration";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const initialState = {
  name: "",
  phone: "",
  email: "",
  address: "",
  company: "",
};

const Register = () => {
  const [agree, setAgree] = useState(false);
  const [values, setValues] = useState(initialState);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const { name, email, phone, address, company } = values;
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    createParticipant(values)
      .then((res) => {
        console.log(res);
        toast.success(`Please processed to payment using QR code.`);
        setValues({
          name: "",
          phone: "",
          email: "",
          address: "",
          company: "",
        });
        setLoading(false);
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 400) toast.error(err.response.data);
      });
  };
  return (
    <div>
      <Header />
      <div className="registers">
        <h2>Registration form Closed !!</h2>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
