import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./style.css";
import Clock from "./Clock";

const Hero = () => {
  const [timerDays, setTimerDays] = useState();
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();

  const startTimer = () => {
    const countDownDate = new Date("09/16/2023 ").getTime();
    let interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (24 * 60 * 60 * 1000));
      const hours = Math.floor(
        (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        // Stop Timer

        clearInterval(interval.current);
      } else {
        // Update Timer
        setTimerDays(days + 1);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };

  useEffect(() => {
    startTimer();
  });

  return (
    <div className="carousels">
      <div className="desktop">
        <Carousel fade className="carousel-fade" interval={10000} pause={false}>
          <Carousel.Item>
            <img
              className="d-block carousel_image w-100"
              src="./images/cover.jpg"
              alt="First slide"
            />
            <div className="timing-box">
              <div className="date_venue">
                <div className="date">
                  <h4>Save The Date</h4>
                  <h5>
                    16<sup>th</sup> September 2023, Saturday
                  </h5>
                </div>
                <div className="venue">
                  <h4>Venue</h4>
                  <h5>
                    Hotel Yak &amp; Yeti Durbar Marg, <br /> Kathmandu, Nepal
                  </h5>
                </div>
              </div>
              <div className="timing">
                <div className="clock">
                  <Clock
                    timerDays={0}
                    timerHours={0}
                    timerMinutes={0}
                    timerSeconds={0}
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <video autoPlay muted loop id="myVideo" className="w-100">
              <source src="./images/video.mp4" type="video/mp4" />
            </video>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="mobile">
        <Carousel fade className="carousel-fade" interval={10000} pause={false}>
          <Carousel.Item>
            <video autoPlay muted loop id="myVideo" className="w-100">
              <source src="./images/video.mp4" type="video/mp4" />
            </video>
          </Carousel.Item>
        </Carousel>
      </div>
      {/* <video autoPlay muted loop id="myVideo" className="w-100">
        <source src="./images/video.mp4" type="video/mp4" />
      </video> */}
    </div>
  );
};

export default Hero;
