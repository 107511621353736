import React from "react";
import "./style.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const News = () => {
  return (
    <div>
      <Header />
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">News</h2>
        </div>
      </div>
      <div className="news-room pt-5 ps-md-0 ps-3 pb-5">
        <div className="row w-100 ps-md-5 pe-md-5 pt-5">
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/worldlinknews.jpg"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>३१ साउन २०८०</i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  तेस्रो युवा उद्यमशीलता शिखर सम्मेलनको इन्टरनेट पार्टनरमा
                  वर्ल्डलिंक
                </h6>
                <p className="text">
                  काठमाडौँ । नेपाल सरकार, राष्ट्रिय युवा परिषद्, बागमती प्रदेश
                  सरकार र नेपाल बिजनेस इन्स्च्यिुट (एनबीआई) ले संयुक्तरुपमा
                  आयोजना गर्ने नेपाल युवा उद्यमशीलता सिखर सम्मेलनको तेस्रो
                  संस्करणमा वर्ल्डलिंक कम्युनिकेशन इन्टरनेट...
                </p>
                <a
                  href="https://www.ratopati.com/story/379613?fbclid=IwAR3m-BnwAstN_FO2Igrw1F1OVYz38efMlc1C8KlKPX5ep4dfMevu384eraY"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/newnew.jpg"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>Jul 19, 2023 </i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  युवा परिषद् र एनबीआईको आयोजनामा तेस्रो युवा उद्यमशीलता शिखर
                  सम्मेलन हुँदै
                </h6>
                <p className="text">
                  नेपाल सरकार, राष्ट्रिय युवा परिषद् र नेपाल बिजनेस इन्स्टिच्युट
                  (एनबीआई) को संयुक्त आयोजनामा यहि भदाै ८ गते काठमाडौँमा नेपाल
                  युवा उद्यमशीलता सिखर सम्मेलनको तेस्रो संस्करण आयोजना हुने भएको
                  छ । ...
                </p>
                <a
                  href="https://bizpati.com/update/71746?fbclid=IwAR1SOFEreo29ACWvmVzQY-QmQFo8hPdSaWjW4WF2pR0--PBtBITRa7mq8TM"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/newnew.jpg"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>Jul 19, 2023 </i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  तेस्रो युवा उद्यमशीलता शिखर सम्मेलन हुँदै
                </h6>
                <p className="text">
                  नेपाल सरकार, राष्ट्रिय युवा परिषद् र नेपाल बिजनेस इन्स्टिच्युट
                  (एनबीआई) को संयुक्त आयोजनामा यहि भाद्र ८ गते काठमाडौँमा नेपाल
                  युवा उद्यमशीलता सिखर सम्मेलनको तेस्रो संस्करण आयोजना हुने भएको
                  छ । सम्मेलन आयोजना सम्बन्धी समझदारी पत्रमा बुधवार राष्ट्रिय
                  युवा परिषद्का कार्यकारी उपाध्यक्ष सुरेन्द्र ...
                </p>
                <a
                  href="https://bizpati.com/update/71746?fbclid=IwAR1SOFEreo29ACWvmVzQY-QmQFo8hPdSaWjW4WF2pR0--PBtBITRa7mq8TM"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/snew1.jpg"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>२२ असार २०८० शुक्रबार</i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  युवा उद्यमशीलता शिखर सम्मेलन आयोजना हुँदै
                </h6>
                <p className="text">
                  युवा उद्यमीलाई प्रोत्साहन गर्दै युवा उद्यमशीलता शिखर सम्मेलन
                  आयोजना हुने भएको छ। सरकार र निजी क्षेत्रको साझेदारीमा आगामी
                  भदौ २८ गते काठमाडौंमा आयोजना हुने नेपाल युवा उद्यमशीलता शिखर
                  सम्मेलन २०२२ को टाइटल स्पोन्सरमा राष्ट्रिय वाणिज्य बैंक रहने
                  भएको छ। ...
                </p>
                <a
                  href="https://www.onlinekhabar.com/2023/05/1300593?fbclid=IwAR1RAv-uSyp5C7O6MeEaAFw5-V7wdRltboKx0USQbT1KdUjbn6tfIUIYuSs"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/newss.jpg"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>६ पुष २०७९</i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  ‘नेपाल व्यवसाय शिखर सम्मेलन २०२३’ आयोजना हुने
                </h6>
                <p className="text">
                  काठमाडौं । नेपाल सरकार, बैंक तथा वित्तीय संस्था परिसंघ नेपाल र
                  नेपाल बिजनेस इन्स्टिच्युटको संयुक्त आयोजना र संघीय सरकारका
                  विभिन्न मन्त्रालयहरुको साझेदारीतामा नेपाल व्यवसाय शिखर सम्मेलन
                  २०२३ आयोजना हुने...
                </p>
                <a
                  href="https://clickmandu.com/2022/12/225401.html"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/newsr.webp"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>२९ फागुन २०७९</i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  बिजनेस समिटको टाइटल स्पोन्सरमा वर्ल्ड लिंक कम्युनिकेसन
                </h6>
                <p className="text">
                  काठमाडौँ । नेपाल बिजनेस समिट २०२३ को टाइटल स्पोन्सरमा वर्ल्ड
                  लिंक कम्युनिकेशन रहने भएको छ । नेपाल सरकार, बैंक तथा वित्तीय
                  संस्था परिसंघ नेपाल र नेपाल बिजनेस इन्स्टिच्युटको संयुक्त
                  आयोजनामा वैशाखको दोस्रो हप्ता आयोजना हुने बिजनेस समिटमा ...
                </p>
                <a
                  href="https://www.nepalbusiness.com/News/Details/2355?fbclid=IwAR3l77bLWgG_4pAkBzosYFZGnK2Nz_4jGZXHivGrN7DG5b0qAE4NDz5-y3U"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/newss1.jpg"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>Dec 22, 2022</i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  Nepal Business Summit 2023 to be organised
                </h6>
                <p className="text">
                  The Nepal government, Confederation of Banks and Financial
                  Institutions Nepal (CBFIN) and Nepal Business Institute (NBI)
                  are jointly organising Nepal Business Summit 2023....
                </p>
                <a
                  href="https://thehimalayantimes.com/business/nepal-business-summit-2023-to-be-organised"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default News;
