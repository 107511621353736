import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getParticipants } from "../../../function/registration";
import { getStalls } from "../../../function/stall";

function NavLink() {
  const [delegates, setDegates] = useState([]);
  const [stalls, setStalls] = useState([]);
  useEffect(() => {
    loadDelegates();
    loadStalls();
  }, []);

  const loadDelegates = () =>
    getParticipants().then((res) => setDegates(res.data.response));
  const loadStalls = () =>
    getStalls().then((res) => setStalls(res.data.response));
  let i = 0;
  let m = 0;
  return (
    <div className="admin-nav-link">
      <div className="admin-heads">
        <h4>
          <Link
            to="/dashboard"
            style={{ color: "#fff", textDecoration: "none" }}
          >
            Nepal Business Summit
          </Link>
        </h4>
      </div>
      <div className="btn-group">
        <Link
          to="/all-delegates"
          style={{
            color: "#fff",
            textDecoration: "none",
            fontSize: "19px",
            fontWeight: "600",
            paddingLeft: "50px",
          }}
        >
          Delegates{" "}
          {delegates.map((d, j) => {
            if (d.isView !== true) {
              i++;
            } else {
              return;
            }
          })}
          <sup className="notif">{i}</sup>
        </Link>
      </div>
      <br />
      <div className="btn-group">
        <Link
          to="/all-stalls"
          style={{
            color: "#fff",
            textDecoration: "none",
            fontSize: "19px",
            fontWeight: "600",
            paddingLeft: "50px",
          }}
        >
          Stalls{" "}
          {stalls.map((d, j) => {
            if (d.isView !== true) {
              m++;
            } else {
              return;
            }
          })}
          <sup className="notif">{m}</sup>
        </Link>
      </div>
      <br />
    </div>
  );
}

export default NavLink;
