import React from "react";
import "./style.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const PastSpeakers = () => {
  return (
    <div>
      <Header />
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center text-white">Past Speakers</h2>
        </div>
      </div>
      <div
        className=" speakers distinguished-guest pb-4 pb-md-5 mt-5  pt-md-4"
        id="speakers"
      >
        <div className="distinguished-guest pb-5 pt-md-3 ">
          <div className="guest pb-4">
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="guest-card">
                <div className="card-img">
                  <img
                    src="./images/distinguished/Prachanda.jpg"
                    alt="...."
                    className="w-100"
                  />
                </div>
                <div className="card-title pb-4">
                  <img
                    src="./images/sarkar.jpg"
                    height={50}
                    alt="...."
                    className="pt-2"
                  />
                  <div className="card-name mt-2 pt-2 ">
                    <h4>Rt. Hon. Pushpa Kamal Dahal</h4>
                  </div>
                  <div className="card-position">
                    <p>Prime Minister of Nepal</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="guest-card">
                <div className="card-img">
                  <img
                    src="./images/pastspeakers/baikuntha.jpg"
                    alt="...."
                    className="w-100"
                  />
                </div>
                <div className="card-title pb-4">
                  <img
                    src="./images/sarkar.jpg"
                    height={50}
                    alt="...."
                    className="pt-2"
                  />
                  <div className="card-name mt-2 pt-2 ">
                    <h4>Dr. Bankuntha Aryal</h4>
                  </div>
                  <div className="card-position">
                    <p>Chief Secretary (GoN)</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="guest-card">
                <div className="card-img">
                  <img
                    src="./images/pastspeakers/makune.jpg"
                    alt="...."
                    className="w-100"
                  />
                </div>
                <div className="card-title pb-4">
                  <img
                    src="./images/sarkar.jpg"
                    height={50}
                    alt="...."
                    className="pt-2"
                  />
                  <div className="card-name mt-2 pt-2 ">
                    <h4>Hon. Madhav Kumar Nepal</h4>
                  </div>
                  <div className="card-position">
                    <p>Former Prime Minister</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="guest-card">
                <div className="card-img">
                  <img
                    src="./images/pastspeakers/yubraj.jpg"
                    alt="...."
                    className="w-100"
                  />
                </div>
                <div className="card-title pb-4">
                  <img
                    src="./images/sarkar.jpg"
                    height={50}
                    alt="...."
                    className="pt-2"
                  />
                  <div className="card-name mt-2 pt-2 ">
                    <h4>Dr. Yubraj Khatiwada</h4>
                  </div>
                  <div className="card-position">
                    <p>Former Finance Minister</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="guest-card">
                <div className="card-img">
                  <img
                    src="./images/pastspeakers/surendra.jpg"
                    alt="...."
                    className="w-100"
                  />
                </div>
                <div className="card-title pb-4">
                  <img
                    src="./images/sarkar.jpg"
                    height={50}
                    alt="...."
                    className="pt-2"
                  />
                  <div className="card-name mt-2 pt-2">
                    <h4>Mr. Surendra Pandey</h4>
                  </div>
                  <div className="card-position">
                    <p>Former Finance Minister</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="guest">
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/maheshwor.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/sarkar.jpg"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Maheswor Jung Gahatraj</h4>
                </div>
                <div className="card-position">
                  <p>Former Youth & Sports Minister</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/chakra.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/sarkar.jpg"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Hon. Chakra Pani Khanal</h4>
                </div>
                <div className="card-position">
                  <p>
                    Former Minister for Agriculture & Livestock Development
                    Ministry
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/shankar.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/sarkar.jpg"
                  height={50}
                  alt="...."
                  className="pt-2"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Shankar Prasad Koirala</h4>
                </div>
                <div className="card-position">
                  <p>Former Finance Minister</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/sushil.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                {/* <img
                        src="./images/speakers/ibn.png"
                        height={50}
                        alt="...."
                        className="pt-2"
                      /> */}
                <div className="card-name mt-2">
                  <h4>Mr. Sushil Bhatta</h4>
                </div>
                <div className="card-position">
                  <p> CEO, Investment Board</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/pawan.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/pastspeakers/cbfin.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Pawan Golyan</h4>
                </div>
                <div className="card-position">
                  <p>Chairman, CBFIN</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="guest">
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/haribhakta.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/pastspeakers/cni.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Haribhakta Sharma</h4>
                </div>
                <div className="card-position">
                  <p>Former President, CNI</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/chandra.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/pastspeakers/fncci.jpg"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Chandra Prasad Dhakal</h4>
                </div>
                <div className="card-position">
                  <p> President, FNCCI </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/bhawani.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/pastspeakers/fncci.jpg"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mrs. Bhawani Rana</h4>
                </div>
                <div className="card-position">
                  <p>President, FNCCI</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/purusottam.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                {/* <img
                        src="./images/speakers/man.jpg"
                        height={50}
                        alt="...."
                        className="ps-2 pe-2 pt-1"
                      /> */}
                <div className="card-name mt-2">
                  <h4>Mr. Purusottam Khanal</h4>
                </div>
                <div className="card-position">
                  <p>Chairman, NTA</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/kiran.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/pastspeakers/rbb.jpg"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Kiran Kumar Shrestha</h4>
                </div>
                <div className="card-position">
                  <p>CEO, Rastriya Banijya Bank</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="guest">
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/mahendra.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/pastspeakers/heifer.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Dr. Mahendra Nath Lohani</h4>
                </div>
                <div className="card-position">
                  <p>Heifer International USA</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/yamkumari.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/sarkar.jpg"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mrs. Yam Kumari Khatiwada</h4>
                </div>
                <div className="card-position">
                  <p> Former Secretary (GoN) </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/hemraj.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/pastspeakers/cni.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Hemraj Dhakal</h4>
                </div>
                <div className="card-position">
                  <p>VP, CNI</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/manoj.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/pastspeakers/nabil.png"
                  height={30}
                  width={160}
                  alt="...."
                  className="ps-2 pe-2 pt-1"
                />
                <div className="card-name mt-2">
                  <h4>C.A. Manoj Kumar Gyawali</h4>
                </div>
                <div className="card-position">
                  <p>Deputy CEO, Nabil Bank</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/dileep.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/pastspeakers/worldlink.png"
                  height={30}
                  width={160}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Dileep Agrawal</h4>
                </div>
                <div className="card-position">
                  <p>Chairman, World Communication</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="guest">
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/anil.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                {/* <img
                  src="./images/pastspeakers/heifer.jpg"
                  height={50}
                  alt="...."
                  className="pt-1"
                /> */}
                <div className="card-name mt-2">
                  <h4>Mr. Anil Kesary Shah</h4>
                </div>
                <div className="card-position">
                  <p>Banker</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/anupama.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                {/* <img
                  src="./images/sarkar.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                /> */}
                <div className="card-name mt-2">
                  <h4>Mrs. Anupama Kunjeli</h4>
                </div>
                <div className="card-position">
                  <p> Banker </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/moushami.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                {/* <img
                  src="./images/pastspeakers/cni.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                /> */}
                <div className="card-name mt-2">
                  <h4>Mrs. Moushami Shrestha</h4>
                </div>
                <div className="card-position">
                  <p>Entrepreneur</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/ranjeet.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                {/* <img
                  src="./images/pastspeakers/n.png"
                  height={50}
                  alt="...."
                  className="ps-2 pe-2 pt-1"
                /> */}
                <div className="card-name mt-2">
                  <h4>Mr. Ranjeet Acharya</h4>
                </div>
                <div className="card-position">
                  <p>Chairman, Startup & Innovation Commitee, FNCCI</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/pastspeakers/tshering.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                {/* <img
                  src="./images/pastspeakers/worldlink.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                /> */}
                <div className="card-name mt-2">
                  <h4>Dr. Tshering Lama</h4>
                </div>
                <div className="card-position">
                  <p>Co-Founder, Idea Studio Nepal</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PastSpeakers;
