import React from "react";
import "./style.css";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const Videos = () => {
  return (
    <div>
      <Header />
      <div className="gallery">
        <div className=" container">
          <div className="gallery-head pb-3">
            <h3>
              <span>previous summit</span> <br /> Videos
            </h3>
          </div>
          <div className="gallery-section">
            <div className="row">
              <div className="col-md-4 mt-4">
                <iframe
                  className="w-100"
                  height={280}
                  src="https://www.youtube.com/embed/F1fmKo5AQoI"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen=""
                />
              </div>
              <div className="col-md-4 mt-4">
                <iframe
                  className="w-100"
                  height={280}
                  src="https://www.youtube.com/embed/2qd-R7CefVc"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen=""
                />
              </div>
              <div className="col-md-4 mt-4">
                <iframe
                  className="w-100"
                  height={280}
                  src="https://www.youtube.com/embed/iOrsEyJ3n-A"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen=""
                />
              </div>
              <div className="col-md-4 mt-4">
                <iframe
                  className="w-100"
                  height={280}
                  src="https://www.youtube.com/embed/Mpl6w34-w3o"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen=""
                />
              </div>
              <div className="col-md-4 mt-4">
                <iframe
                  className="w-100"
                  height={280}
                  src="https://www.youtube.com/embed/UpSKtj5CVqU"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen=""
                />
              </div>
              <div className="col-md-4 mt-4">
                <iframe
                  className="w-100"
                  height={280}
                  src="https://www.youtube.com/embed/5pa6JFBgIKY"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Videos;
