import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { FaRegFilePdf } from "react-icons/fa";
import "./style.css";

const ProgressReport = () => {
  return (
    <div>
      <Header />
      <div className="inner-hero ">
        <div className="parallalx">
          <div className="parallal-content">
            <h4 className="ps-2 pe-2">Progress Report</h4>
          </div>
        </div>
      </div>
      <div className="col-md-6 offset-md-3 mt-5">
        <div className="report-card">
          <div className="d-flex">
            <div className="col-2">
              <div className="picon aicon">
                <FaRegFilePdf className="pdficon" />
              </div>
            </div>
            <div className="col-10">
              <div className="card-content p-3">
                <span> 2022</span>
                <h4>Nepal Youth Entrepreneurship</h4>
                <span>"Youth Innovation : Investment and Growth"</span>
                <a href="./images/progress.pdf" target="__blank">
                  <button className="view aview">View</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProgressReport;
