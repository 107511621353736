import React from "react";

import "./style.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const Speaker = () => {
  return (
    <div>
      <Header />
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center text-white">All Speakers</h2>
        </div>
      </div>
      <div
        className=" speakers distinguished-guest pb-4 pb-md-5 mt-5  pt-md-4"
        id="speakers"
      >
        <div className="distinguished-guest pb-5 pt-md-3 ">
          <div className="guest pb-4">
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="guest-card">
                <div className="card-img">
                  <img
                    src="./images/distinguished/Prachanda.jpg"
                    alt="...."
                    className="w-100"
                  />
                </div>
                <div className="card-title pb-4">
                  <img
                    src="./images/sarkar.jpg"
                    height={50}
                    alt="...."
                    className="pt-2"
                  />
                  <div className="card-name mt-2 pt-2 ">
                    <h4>Rt. Hon. Pushpa Kamal Dahal</h4>
                  </div>
                  <div className="card-position">
                    <p>Prime Minister of Nepal</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="guest-card">
                <div className="card-img">
                  <img
                    src="./images/distinguished/finance.jpg"
                    alt="...."
                    className="w-100"
                  />
                </div>
                <div className="card-title pb-4">
                  <img
                    src="./images/sarkar.jpg"
                    height={50}
                    alt="...."
                    className="pt-2"
                  />
                  <div className="card-name mt-2 pt-2 ">
                    <h4>Hon. Prakash Sharan Mahat</h4>
                  </div>
                  <div className="card-position">
                    <p>Minister For Finance (GoN)</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="guest-card">
                <div className="card-img">
                  <img
                    src="./images/distinguished/rekha.jpg"
                    alt="...."
                    className="w-100"
                  />
                </div>
                <div className="card-title pb-4">
                  <img
                    src="./images/sarkar.jpg"
                    height={50}
                    alt="...."
                    className="pt-2"
                  />
                  <div className="card-name mt-2 pt-2 ">
                    <h4>Hon. Rekha Sharma</h4>
                  </div>
                  <div className="card-position">
                    <p>
                      Minister For Communications and Information Technology
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="guest-card">
                <div className="card-img">
                  <img
                    src="./images/distinguished/rameshrijal.jpg"
                    alt="...."
                    className="w-100"
                  />
                </div>
                <div className="card-title pb-4">
                  <img
                    src="./images/sarkar.jpg"
                    height={50}
                    alt="...."
                    className="pt-2"
                  />
                  <div className="card-name mt-2 pt-2 ">
                    <h4>Hon. Ramesh Rijal</h4>
                  </div>
                  <div className="card-position">
                    <p>Minister for Industry, Commerce and Supplies</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="guest-card">
                <div className="card-img">
                  <img
                    src="./images/guests/dil.jpg"
                    alt="...."
                    className="w-100"
                  />
                </div>
                <div className="card-title pb-4">
                  <img
                    src="./images/sarkar.jpg"
                    height={50}
                    alt="...."
                    className="pt-2"
                  />
                  <div className="card-name mt-2 pt-2">
                    <h4>Hon. Dig Bahadur Limbu </h4>
                  </div>
                  <div className="card-position">
                    <p>Minister for Youth and Sports Ministry, GoN</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="guest">
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/speakers/neelam.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/speakers/nrb.jpg"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Dr. Neelam Dhungana Timsina</h4>
                </div>
                <div className="card-position">
                  <p>Acting Governor</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/speakers/sushil-bhatta.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/speakers/ibn.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Sushil Bhatta</h4>
                </div>
                <div className="card-position">
                  <p>CEO, Investment Board Nepal</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/speakers/roman.png"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/speakers/indrive.png"
                  height={50}
                  alt="...."
                  className="pt-3"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Roman N. Ermoshin</h4>
                </div>
                <div className="card-position">
                  <p>
                    Director, Asia Pacific Region,{" "}
                    <span style={{ textTransform: "lowercase" }}>in</span>
                    Drive
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/speakers/ashish.png"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/speakers/alogo.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Ashish Sharma</h4>
                </div>
                <div className="card-position">
                  <p>Managing Director, Ashviana Corporation</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/speakers/dilip.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/speakers/worldlink.png"
                  height={20}
                  alt="...."
                  className="mt-3 mb-2"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Dileep Agrawal</h4>
                </div>
                <div className="card-position">
                  <p>Chairman, Worldlink Communications</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="guest">
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/speakers/bhawani.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/speakers/fncci.jpg"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mrs. Bhawani Rana</h4>
                </div>
                <div className="card-position">
                  <p>Former President, FNCCI</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/speakers/sunil.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/speakers/nba.jpg"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Sunil KC</h4>
                </div>
                <div className="card-position">
                  <p>President, NBA</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/speakers/mahesh.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/speakers/nrna.png"
                  height={60}
                  alt="...."
                  className="pt-3"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Mahesh Shrestha</h4>
                </div>
                <div className="card-position">
                  <p>Vice President IES Japan</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/speakers/suyog.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/speakers/sangrila.jpg"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Suyog Shrestha</h4>
                </div>
                <div className="card-position">
                  <p>CEO, Shangri-la Development Bank Ltd.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/speakers/ghanashyam.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/speakers/wwf.png"
                  height={40}
                  alt="...."
                  className="mt-3 mb-2"
                />
                <div className="card-name mt-2">
                  <h4>Dr. Ghana S. Gurung</h4>
                </div>
                <div className="card-position">
                  <p>Country Representative, WWF Nepal</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="guest">
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/speakers/vidhusi.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/speakers/gold.png"
                  height={40}
                  alt="...."
                  className="pt-2 ps-3 pe-3"
                />
                <div className="card-name mt-2">
                  <h4>Mrs. Vidusi Rana</h4>
                </div>
                <div className="card-position">
                  <p>Executve Director GoldStar</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/speakers/sunita.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/speakers/bc.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Ms. Sunita Basnet</h4>
                </div>
                <div className="card-position">
                  <p>Head of the School of Business</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/speakers/diwas.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/speakers/phonepay.png"
                  height={50}
                  alt="...."
                  className="pt-3"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Diwas Sapkota</h4>
                </div>
                <div className="card-position">
                  <p>Chief Executive Officer at Fonepay</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Speaker;

