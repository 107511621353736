import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

const Partners = () => {
  return (
    <div
      className="partners container pt-5 pb-4"
      style={{ borderBottom: "3px solid #14469f" }}
    >
      <div className="row">
        <div className="col-md-9">
          <div className="partners-head pb-3 pb-md-4">
            <h3>Joint Initiative</h3>
          </div>
          <div className="row w-100">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6 mb-md-0 partner ps-5 pe-5 ps-md-5 pe-md-5">
                  <center>
                    <img src="./images/partners/glogo.png" alt="..." />
                  </center>
                </div>
                <div className="col-md-6 mb-md-0 partner">
                  <center>
                    <img src="./images/partners/nyc.png" alt="..." />
                  </center>
                </div>
              </div>
              <center>
                <span
                  style={{
                    color: "#0b76bc",
                  }}
                >
                  Government of Nepal <br />
                  National Youth Council (NYC)
                </span>
              </center>
            </div>
            <div className="col-md-4 mb-3">
              <center>
                <img
                  src=" ./images/partners/nbi.png"
                  className="w-100 ps-4 pe-4 pnbi mt-3"
                  alt="..."
                />
                <br />
                <span
                  style={{
                    color: "#0b76bc",
                  }}
                >
                  Nepal Business Institute (NBI)
                </span>
              </center>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="partners-head pb-3 pt-4 pt-lg-0 pb-md-4">
            <h3>Tittle Sponsor</h3>
          </div>
          <center>
            <img
              src=" ./images/partners/nrbb.jpg"
              className="w-100 ps-2 pe-2 mt-2"
              alt="..."
            />
            <br />
            <span
              style={{
                color: "#0b76bc",
              }}
            >
              Rastriya Banijya Bank Limited (RBBL)
            </span>
          </center>
        </div>
      </div>
    </div>
  );
};

export default Partners;
