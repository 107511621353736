import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { RiArrowRightSLine } from "react-icons/ri";
import { FaHandPointRight } from "react-icons/fa";
import "./style.css";
import { Link } from "react-router-dom";

const Faq = () => {
  return (
    <div>
      <Header />
      <div className="inner-hero ">
        <div className="parallalx">
          <div className="parallal-content">
            <h4>
              Home <RiArrowRightSLine />
              FAQ
            </h4>
          </div>
        </div>
      </div>
      <div className="faq p-5">
        <div className="col-md-10 offset-md-1">
          <div className="faq-head">
            <h2>Frequently Asked Questions (FAQ's)</h2>
          </div>
          <div className="qa pt-md-4">
            <h4>Q1. What is Nepal Youth Entrepreneurship Summit, 2023?</h4>
            <p>
              <span>
                <FaHandPointRight className="hand" />
              </span>{" "}
              Nepal Youth Entrepreneurship Summit (NYES)-2023 is the national
              level summit going to be held on 16 th September, 2023 in Hotel
              Yak and Yeti, DurbarMarg, Kathmandu jointly orgnazined by
              Government of Nepal (GoN), National Youth Council (NYC), Bagmati
              Province Government and Nepal Business Institute (NBI) to mark an
              important milestone in the roadmap towards boosting an ecosystem
              for entrepreneurs in Nepal.
            </p>
            <p>
              <span>
                <FaHandPointRight className="hand" />
              </span>{" "}
              <b>“Youth Innovation, Investment and Growth”</b> is the main theme
              for this summit. This is a follow up summit of previously held
              First Nepal Youth Entrepreneurship Summit on February, 14, 2019
              and second Nepal Youth Entrepreneurship Summit on September 11,
              2022 inaugurated by current Prime Minister of Nepal, The Right
              Hon’ble Puspa Kamal Dahal.
            </p>
            <h4>Q2. What does the summit objectives for?</h4>
            <p>
              <ul>
                <li>
                  <FaHandPointRight className="hand" /> To find the solutions on
                  the latest economic crisis and to identifiy the prority areas
                  of the investment for promoting youth entrepreneurship and
                  attract young to start-ups.
                </li>
                <li>
                  <FaHandPointRight className="hand" /> For high level
                  networking and investment deals between young people who want
                  to become entreprenuers and investors who are investing in
                  startups.
                </li>
                <li>
                  <FaHandPointRight className="hand" /> To conduct workshops on
                  youth financing, government policies, startup programs, story
                  sharing, group discussions and startups in different sessions
                  to increase the awareness of young entrepreneurs and
                  facilitate business scale.
                </li>
                <li>
                  <FaHandPointRight className="hand" /> To increase the
                  meaningful participation and awareness of youth in achieving
                  the goals of sustainable development to develop a responsible
                  buisiness culture.
                </li>
                <li>
                  <FaHandPointRight className="hand" /> To re-orient the
                  government for the current economic challenges and way
                  forward.
                </li>
                <li>
                  <FaHandPointRight className="hand" /> Protecting large
                  industries, encouraging small, medium and startups.
                </li>
                <li>
                  <FaHandPointRight className="hand" /> To organize an
                  exhibition of indigenous products and innovative services
                  produced by young entreprenuers.
                </li>
                <li>
                  <FaHandPointRight className="hand" /> To mentor the youth and
                  to respect and encourage the successful industries run by the
                  youth.
                </li>
                <li>
                  <FaHandPointRight className="hand" /> To review the policies,
                  programs and budgets made by the three tiers of government and
                  advocate towards making the youth entrepreneurship friendly
                  policy, program and budget accordingly.
                </li>
                <li>
                  <FaHandPointRight className="hand" /> To achieve the economic
                  prosperity of nation through construction and implementing
                  Youth Entrepreneurship Vision, 2030.
                </li>
                <li>
                  <FaHandPointRight className="hand" /> To make an appropriate
                  arrangement in the monetary policy for banks access to youth
                  enterprises and to make positive about youth entreprenuership
                  institutitions like Nepal Rastra Bank, Nepal Banker&#39;s
                  Association, CBFIN and others.
                </li>
                <li>
                  <FaHandPointRight className="hand" /> To promote the &#39;Made
                  in Nepal&#39; products through stalls.
                </li>
              </ul>
            </p>
            <h4>Q3. Who are the attendees of this summit?</h4>
            <p>
              <span>
                <FaHandPointRight className="hand" />
              </span>
              The NYE Summit, 2023 will be inaugurated by the current Prime
              Minister of Nepal, The Right Hon’ble Puspa Kamal Dahal. 500 young
              entrepreneurs from more than 60 districts of Nepal are
              participating this summit. High level political leaders, law
              makers, bureaucrats, diplomats and representative of Donor
              agencies, development partners, private bodies, banks and
              financial institutions (BFI), corporate houses, renowned senior
              entrepreneurs, students are attending this summit in order to make
              this summit effective &amp; productive.
            </p>
            <h4>Q4. When and where the summit be held?</h4>
            <p>
              <span>
                <FaHandPointRight className="hand" />
              </span>
              The summit will be held on 16 <sup>th </sup> September,
              2023(Saturday) in Hotel Yak &amp; Yeti, Durbar Marg, Kathmandu,
              Nepal.
            </p>
            <h4>Q5. What are the summit highlights?</h4>
            <p>
              <span>
                <FaHandPointRight className="hand" />
              </span>
              High level of panel discussion on fostering youth
              entrepreneurship, success stories and experience sharing,
              presentation from the successful entrepreneurs, facilitation to
              successful startups for investment, counselling desks,
              mentorship,Policy Reform, policy, programs &amp; budget youth
              entrepreneurship friendly, entrepreneurship loan, enterprise fair,
              demonstration of innovative ideas practiced at global market and
              business networking opportunities are the key highlights of this
              summits.
            </p>
            <h4>
              Q 6. What are the partnership and opportunities for this summit
              and what benefits do I get?
            </h4>
            <p>
              <span>
                <FaHandPointRight className="hand" />
              </span>
              You are hearty welcome for the partnership for Nepal Youth
              Entrepreneurship Summit, 2023. Visibility and Branding, Relevant
              Thematic Session, Fostering Youth entrepreneurship, Stronger
              Business Networking Opportunities, Exhibition, Opportunity to
              connect your business in youth community is the major that you
              will receive from this summit.
            </p>
            <h4>
              Q7. How do I register and what is the registration fee to
              participate in the summit?
            </h4>
            <p>
              <ul>
                <li>
                  <FaHandPointRight className="hand" /> In order to register for
                  this summit, please click the link below and fill up the
                  necessary details. <br />
                  Link for the registration:
                  <Link to="/register">
                    https://www.nyesummit.org/register{" "}
                  </Link>
                </li>
                <li>
                  <FaHandPointRight className="hand" /> For Institutional
                  Representative, registration fee is NPR. 10000.00(Ten thousand
                  only) per person.
                </li>
                <li>
                  <FaHandPointRight className="hand" /> For entrepreneurs,
                  registration fee is NPR. 5000.00 (Five thousand only) per
                  person.
                </li>
                <li>
                  <FaHandPointRight className="hand" /> For student,
                  registration fee is NPR. 4000.00(Four thousand only) per
                  person. Please submit your copy of ID card too. <br />
                </li>
              </ul>
            </p>

            <h4>Q8. What are the payment options available for the summit?</h4>
            <p>
              <span>
                <FaHandPointRight className="hand" />
              </span>
              Registration can be made payment through online medium or can be
              deposit directly to the bank account details of Nepal Business
              Institute.
              <div className="ps-5">
                <div className="col-md-4 pb-4">
                  <img src="./images/qr.png" className="w-100" alt="" />
                </div>
                <h5 className="pb-3">
                  <a className="text-black">Payment Method</a>
                </h5>
                <h6>
                  Bank Name and Branch: NMB Bank, Babarmahal, Kathmandu, Nepal
                </h6>
                <h6>A/C No: 0010145270300012</h6>
                <h6>A/C Name: Nepal Business Institute Pvt. Ltd.</h6>
                <p>
                  (Tea, Coffee, Lunch, Snacks, dinner will be available for
                  registered ones)
                </p>
              </div>
            </p>
            <div className="note_faq">
              <p>
                To know more details about the Nepal Youth Entreprenuers Summit
                (NYES), 2023 Contact: Ms. Dipisha Ale, Nepal Business Institute
                (NBI) <br />
                Phone: +977-9841049104 <br />
                Email: nyesummit.org@gmail.com <br />
                Website: www.nyesummit.org
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faq;
