import React, { useState, useEffect } from "react";
import NavHead from "../../nav/NavHead";
import NavLink from "../../nav/NavLink";
import { getParticipant } from "../../../../function/registration";
import "../../dashboard.css";
import { useParams } from "react-router-dom";

const SingleDelegate = () => {
  const [delegate, setDegate] = useState({});
  const [loading, setLoading] = useState(false);

  const params = useParams();
  useEffect(() => {
    loadDelegate();
  }, []);

  const loadDelegate = () =>
    getParticipant(params.id).then((res) => setDegate(res.data.participant));
  return (
    <div className="dash-contact row w-100">
      <div className="col-md-2 dash-nav">
        <NavLink />
      </div>
      <div className="col-md-10 dash-details">
        <NavHead />
        <hr />
        <div className="dash-screen">
          <div className="tabs">
            <div className="details">
              <p>
                <span>Name:</span> {delegate.name}
              </p>
              <p>
                <span>Address:</span> {delegate.address}
              </p>
              <p>
                <span>Contact Number:</span> {delegate.phone}
              </p>
              <p>
                <span>Email:</span> {delegate.email}
              </p>
              <p>
                <span>Company Name:</span> {delegate.company}
              </p>
              <p className={delegate.isPaid == "Paid" ? "paid" : "notPaid"}>
                <span>Payment Status:</span>{" "}
                {delegate.isPaid == "Paid" ? <>Paid</> : <>Not Paid</>}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDelegate;
