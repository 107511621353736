import React from "react";
import { FacebookProvider, Page } from "react-facebook";
import "./style.css";
import info from "../../img/arjun.jpg";
import { FaMapMarkerAlt, FaEnvelope } from "react-icons/fa";
import { BsTelephoneFill, BsGlobe } from "react-icons/bs";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="pt-5  footers mt-5" id="footer">
      <footer className="footer pb-5 container">
        <div className="row w-100">
          <div className="col-lg-4 col-md-6 footer-mbl">
            <h3 className="title">Contact Details</h3>
            <div className="contact_details pt-4">
              <h5>
                <FaMapMarkerAlt /> Dillibazar, Kathmandu,Nepal
              </h5>
              <h5>
                <BsTelephoneFill /> 9841049104
              </h5>
              <h5>
                <FaEnvelope /> nepalbusinesssummit@gmail.com
              </h5>
              <h5>
                <BsGlobe /> www.nepalbusinesssummit.com{" "}
              </h5>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="arjun">
              <h3 className="title founder">Program Officer</h3>
              <div
                className="text-center"
                style={{
                  marginTop: 40,
                  backgroundColor: "#fff",
                  width: "80%",
                  height: 250,
                }}
              >
                <div style={{ paddingTop: 20 }}>
                  <img src={info} width={120} alt="..." height={120} />
                  <h5
                    style={{
                      color: "#284e9c",
                      paddingTop: 10,
                      marginBottom: 2,
                    }}
                  >
                    Mr. Arjun Neupane
                  </h5>
                  <h5 style={{ marginBottom: 3, fontWeight: 400 }}>
                    Program officer
                  </h5>
                  <h5
                    style={{ marginBottom: 2, fontWeight: 400, fontSize: 18 }}
                  >
                    <BsTelephoneFill /> 9841049104
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 right-section">
            <div className="footer-social-icon ">
              <li className="ficon">
                <FaFacebookF className="social_icon" />
              </li>
              <li className="ficon">
                <FaTwitter className="social_icon" />
              </li>
              <li className="ficon">
                <FaLinkedinIn className="social_icon" />
              </li>
              <li className="ficon">
                <FaYoutube className="social_icon" />
              </li>
            </div>
            <div className="mt-md-3 pt-2 page">
              <FacebookProvider appId="993140114703555">
                <Page
                  href="https://www.facebook.com/nyes2022"
                  tabs="timeline"
                  height="250px"
                />
              </FacebookProvider>
            </div>
          </div>
        </div>
      </footer>
      <div
        style={{ backgroundColor: "#102349", padding: "0% 12%" }}
        className="power"
      >
        <h5
          style={{
            color: "#fff",
            fontWeight: 400,
            fontSize: "18px",
            padding: "10px 0px",
            marginBottom: "0px",
          }}
        >
          Nepal Youth Entrepreneurship Summit &copy; 2023.
        </h5>
        <p className="text-white mt-2">
          Powered By:
          <a
            href="https://sinepal.com/"
            target="__blank"
            className="text-white"
          >
            Smart Innovations Nepal
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
