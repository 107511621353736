import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import logo from "../../img/logo.png";

const Header = () => {
  return (
    <div className="sticky-top barss ">
      <Navbar collapseOnSelect expand="lg" className="navbars">
        <Link to="/">
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="mt-2">
            <li className="nav_link">
              <span>Summit 2023</span>
              <ul className="navdropdown">
                <Link to="/about-the-summit" className=" text-decoration-none">
                  <li>About the Summit</li>
                </Link>
                <Link
                  to="/about-the-organizer"
                  className=" text-decoration-none"
                >
                  <li>About the Organizer</li>
                </Link>
                <Link to="/about-nepal" className=" text-decoration-none">
                  <li>About Nepal</li>
                </Link>

                <Link to="/faq" className=" text-decoration-none">
                  <li>FAQ</li>
                </Link>
                <Link
                  to="/entrepreneurship-nepal"
                  className=" text-decoration-none"
                >
                  <li>Entrepreneurship Nepal</li>
                </Link>
              </ul>
            </li>
            <li className="nav_link">
              <span>Previous Editions</span>
              <ul className="navdropdown">
                <Link to="/progress-report" className=" text-decoration-none">
                  <li>Progress Report</li>
                </Link>
                <Link to="/past-speakers" className=" text-decoration-none">
                  <li>Past Speakers</li>
                </Link>
                <Link to="/past-sponsors" className=" text-decoration-none">
                  <li>Past Sponsors</li>
                </Link>
                <Link to="/gallery" className=" text-decoration-none">
                  <li>Event Gallery</li>
                </Link>
                <Link to="/videos" className=" text-decoration-none">
                  <li>Event Videos</li>
                </Link>
              </ul>
            </li>
            <Link to="/agenda" className=" text-decoration-none text-dark">
              <li className="nav_link">
                <span>Agenda</span>
              </li>
            </Link>

            <Link to="/all-speakers" className="text-dark text-decoration-none">
              <li className="nav_link">
                <span>Speakers</span>
              </li>
            </Link>

            <li className="nav_link">
              <span>Media Room</span>
              <ul className="navdropdown">
                <li>Press Release</li>
                <Link to="/news" className=" text-decoration-none">
                  <li>News Coverage</li>
                </Link>
                <Link to="/videos" className=" text-decoration-none">
                  <li>Videos</li>
                </Link>

                <Link to="/gallery" className=" text-decoration-none">
                  <li>Gallery</li>
                </Link>
                <li>Reports</li>
              </ul>
            </li>
          </Nav>

          <Nav className="mt-2">
            <Link to="/register">
              <button className="head_register_button">Register</button>
            </Link>
          </Nav>
          <Nav className="mt-4 mt-lg-2">
            <Link to="/book-a-stall">
              <button className="stall_button">Book A Stall</button>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
