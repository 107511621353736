import React, { useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import toast from "react-hot-toast";
import "./style.css";
import { createStall } from "../../function/stall";

const initialState = {
  name: "",
  phone: "",
  email: "",
  address: "",
  category: "",
  designation: "",
  message: "",
};

const Stall = () => {
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const { name, email, phone, address, category, designation, message } =
    values;
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    createStall(values)
      .then((res) => {
        console.log(res);
        toast.success(
          `Message sent!. We will contact you as soon as possible.`
        );
        setValues({
          name: "",
          phone: "",
          email: "",
          address: "",
          category: "",
          designation: "",
          message: "",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 400) toast.error(err.response.data);
      });
  };

  return (
    <>
      <Header />
      <div>
        <div className="about-head p-3 p-md-5">
          <div className="container">
            <center>
              <h2>Stall Form Closed!!</h2>
            </center>
          </div>
        </div>
        <div className="container about pt-2 pt-md-5 pb-2 pb-md-5">
          <div className="abouts pt-lg-5 pb-lg-5">
            <div className="col-lg-5 mt-md-5">
              <div className="about-img">
                <img
                  className="w-100"
                  src="./images/stall.jpg"
                  alt="Second slide"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-content">
                <h4 className="text-white">About NYES-2023: Stall</h4>
                <p className="">
                  This <b>Nepal Youth Entrepreneurship Summit (NYES) -2023</b>{" "}
                  is a great platform designed to present or highlight your
                  brands in a most effective way through maximum visibility,
                  reach and impact through your most target audience
                  contributing to the national economy.
                </p>
                <h4 className="text-white">Who should display and why?</h4>
                <p>
                  Youth innovators, startups, enterprise supported by Donor
                  Agencies, travel and tourism services, IT services, business
                  related to <b>-Made in Nepal Products</b> such as Nepali
                  clothing, Nepali handicrafts can display their products or
                  services through a stall desk. This stall is very much
                  important in order to present the product or services in most
                  effective and appealing way contributing to your business
                  among high level political leaders, law makers, bureaucrats,
                  diplomats, representative of Donor Agencies, private bodies,
                  banking and financial institutions (BFI), corporate houses,
                  renowned senior entrepreneurs, young entrepreneurs and
                  startups.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Stall;

