import React from "react";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Pitch from "./pages/InnerPage/Pitch";
import OrganizingC from "./pages/InnerPage/OrganizingC";
import AdvisoryC from "./pages/InnerPage/AdvisoryC";
import AboutNye from "./pages/InnerPage/AboutNye";
import Speaker from "./pages/InnerPage/Speaker";
import Welcome from "./pages/InnerPage/Welcome";
import ThemeA from "./pages/InnerPage/ThematicA";
import Schedule from "./pages/InnerPage/Schedule";
import AboutOrg from "./pages/InnerPage/AboutOrg";
import PastNyesummit from "./pages/InnerPage/PastNyesummit";
import Faq from "./pages/InnerPage/Faq";
import Register from "./pages/InnerPage/Register";
import Gallery from "./pages/Gallery";
import Highlight from "./pages/Highlight";
import News from "./pages/InnerPage/News";
import AboutNepal from "./pages/InnerPage/AboutNepal";
import EntreprenerushipNepal from "./pages/InnerPage/EntreprenerushipNepal";
import Agenda from "./pages/InnerPage/Agenda";
import Videos from "./pages/Videos";
import ProgressReport from "./pages/InnerPage/ProgressReprot";
import PastSpeakers from "./pages/InnerPage/PastSpeakers";
import PastSponsors from "./pages/InnerPage/PastSponsors";
import Stall from "./pages/InnerPage/Stall";
import Login from "./pages/auth/Login";
import PrivateRoute from "./components/routes/PrivateRoute";
import Dashboard from "./components/admin/Dashboard";
import AllDelegates from "./components/admin/pages/delegates/AllDelegates";
import SingleDelegate from "./components/admin/pages/delegates/SingleDelegate";
import AllStalls from "./components/admin/pages/stalls/AllStalls";
import SingleStall from "./components/admin/pages/stalls/SingleStall";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/pitch" element={<Pitch />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/about-the-organizer" element={<AboutOrg />} />
        <Route path="/progress-report" element={<ProgressReport />} />
        <Route path="/past-speakers" element={<PastSpeakers />} />

        <Route path="/about-nepal" element={<AboutNepal />} />
        <Route path="/book-a-stall" element={<Stall />} />
        <Route
          path="/entrepreneurship-nepal"
          element={<EntreprenerushipNepal />}
        />
        <Route path="/organizing-committee" element={<OrganizingC />} />
        <Route path="/advisory-committee" element={<AdvisoryC />} />
        <Route path="/past-sponsors" element={<PastSponsors />} />
        <Route path="/about-the-summit" element={<AboutNye />} />
        <Route path="/all-speakers" element={<Speaker />} />
        <Route path="/welcome-note" element={<Welcome />} />
        <Route path="/thematic-area" element={<ThemeA />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/about-organizer" element={<AboutOrg />} />
        <Route path="/nyesummit-2019" element={<PastNyesummit />} />
        <Route path="/register" element={<Register />} />
        <Route path="/highlight" element={<Highlight />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/news" element={<News />} />

        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/all-delegates" element={<AllDelegates />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/delegate/:id" element={<SingleDelegate />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/all-stalls" element={<AllStalls />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/stall/:id" element={<SingleStall />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
